import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { Link, useHistory, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { CLIENT_DELETE } from '../../graphql/mutations';
import { CLIENTS } from '../../graphql/queries';
import { ClientDeleteType, ClientDeleteVariablesType, ClientsType } from '../../graphql/types';
import Loading from '../Loading';

import '../style/client.scss';

type Props = {
  currentUser: any;
};

const Clients: React.FC<Props> = (props) => {
  const { id } = useParams();
  let { clientId } = useParams();
  const history = useHistory();
  const { loading, data } = useQuery<ClientsType>(CLIENTS, {
    variables: { homeId: id },
  });

  const [clientDeleted] = useMutation<ClientDeleteType, ClientDeleteVariablesType>(CLIENT_DELETE, {
    variables: { id },
    update: (cache, client) => {
      const result = client.data?.clientDelete;
      const query = cache.readQuery<ClientsType>({
        variables: { homeId: id },
        query: CLIENTS,
      });
      if (result && query) {
        const filtered = query?.clients.edges.filter(({ node }) => node.id !== result.id) || [];
        clientId = filtered[0].node.id;
        cache.writeQuery<ClientsType>({
          variables: { homeId: id },
          query: CLIENTS,
          data: {
            ...query,
            clients: {
              ...query?.clients,
              edges: filtered,
            },
          },
        });
        history.push(`/homes/${id}/clients/${clientId}`);
      }
    },
  });

  const removeClient = (node: any) => {
    swal({
      title: 'DELETE CLIENT',
      text: `This action will remove the client(${node.name}), you will not be able to recover this client information.`,
      icon: 'error',
      buttons: ['Cancel', 'Delete Client'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        clientDeleted({ variables: { id: node.id } });
      }
    });
  };

  const renderClient = (node: any) => {
    if (props.currentUser && props.currentUser.admin) {
      return (
        <div key={node.id} className={`client ${node.id === clientId ? 'selected' : ''}`}>
          <Link to={`/homes/${id}/clients/${node.id}`}>{node.name}</Link>

          <span className="action-remove">
            <BiTrash onClick={() => removeClient(node)} />
          </span>

          <span className="action-edit">
            <BiPencil onClick={() => history.push(`/clients/${node.id}/edit`)} />
          </span>
        </div>
      );
    }

    return (
      <div key={node.id} className={`client ${node.id === clientId ? 'selected' : ''}`}>
        <Link to={`/homes/${id}/clients/${node.id}`}>{node.name}</Link>
      </div>
    );
  };

  if (loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="clients-content">
      <span className="client-header">Clients</span>
      {data &&
        data.clients.edges.map(({ node }) => {
          return renderClient(node);
        })}
    </div>
  );
};

export default Clients;
