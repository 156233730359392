import React from 'react';
import { useQuery } from '@apollo/client';
import { Route, useParams, useHistory } from 'react-router-dom';
import { PlanType, ClientType } from '../../graphql/types';
import { PLAN, CLIENT } from '../../graphql/queries';
import Loading from '../Loading';
import NewGoal from './NewGoal';
import EditGoal from './EditGoal';
import GoalList from './GoalList';

import '../style/home.scss';

const Goals: React.FC = () => {
  const { id, planId, clientId } = useParams();
  const history = useHistory();
  const planQuery = useQuery<PlanType>(PLAN, {
    variables: { id: planId },
  });

  const clientQuery = useQuery<ClientType>(CLIENT, {
    variables: { id: clientId },
  });

  if (planQuery.loading || clientQuery.loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  if (!clientQuery.data || !planQuery.data) return null;

  return (
    <div className="home-view-content">
      <div className="group-header">
        <span className="header">
          Client: {clientQuery.data.client.name} - Plan: {planQuery.data.plan.index}
        </span>
        <button
          className="add-button left goal"
          type="button"
          onClick={() => history.push(`/homes/${id}/clients/${clientId}`)}
        >
          Back
        </button>
        <button
          className="add-button right"
          type="button"
          onClick={() => history.push(`/homes/${id}/plan/${planId}/client/${clientId}/goals/new`)}
        >
          Add Goal
        </button>
      </div>

      <div className="client-list-container">
        <GoalList />
      </div>

      <div className="plan-list-container">
        <Route component={NewGoal} path="/homes/:id/plan/:planId/client/:clientId/goals/new" />
        <Route
          component={EditGoal}
          path="/homes/:id/plan/:planId/client/:clientId/goals/:goalId/edit"
        />
      </div>
    </div>
  );
};

export default Goals;
