/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import { useHistory } from 'react-router-dom';

const SettingsList: React.FC = () => {
  const history = useHistory();
  const onclick = () => {
    history.push(`/signature`);
  };

  return (
    <div className="user-list-warper">
      <div className="users-list-container">
        <ul className="setings-containrt-list">
          <li>
            <div role="presentation" onClick={onclick} onKeyPress={() => {}}>
              Set Signature
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SettingsList;
