import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { GOAL } from '../../../graphql/queries';
import { GOAL_UPDATE } from '../../../graphql/mutations';
import {
  GoalUpdateType,
  GoalUpdateVariablesType,
  GoalType,
  GoalVariablesType,
} from '../../../graphql/types';

import '../../style/forms.scss';
import Loading from '../../Loading';

interface Inputs {
  target: string;
  trainingEnvironment: string;
  specificTargetBehavior: string;
  baselinePromptLevel: string;
  notes: string;
  verb: string;
}

const EditGoal: React.FC = () => {
  const history = useHistory();
  const { id, planId, clientId, goalId } = useParams();
  const { register, handleSubmit, errors, reset } = useForm<Inputs>();
  const { loading, data } = useQuery<GoalType, GoalVariablesType>(GOAL, {
    variables: { id: goalId },
    onCompleted: ({
      goal: {
        target,
        trainingEnvironment,
        specificTargetBehavior,
        baselinePromptLevel,
        notes,
        verb,
      },
    }) =>
      reset({
        target,
        trainingEnvironment,
        specificTargetBehavior,
        baselinePromptLevel,
        notes,
        verb,
      }),
  });
  const [goalUpdate] = useMutation<GoalUpdateType, GoalUpdateVariablesType>(GOAL_UPDATE, {
    onCompleted: () => history.push(`/homes/${id}/plan/${planId}/client/${clientId}/goals`),
  });

  const onSubmit = (values: Inputs) => {
    goalUpdate({ variables: { id: goalId, data: values } });
  };

  if (loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="form-container plan-form">
      {data && (
        <form onSubmit={handleSubmit(onSubmit)} className="new-edit-form plan-form">
          <span className="form-header">Edit Goal</span>
          <div className="fields">
            <div className="form-label">Target</div>
            <input className="goal" type="text" name="target" ref={register({ required: true })} />
            {errors.target && <div className="error-form">Target is required*</div>}
          </div>

          <div className="fields-textarea">
            <div className="form-label">Training Environment</div>
            <textarea
              className="goal"
              name="trainingEnvironment"
              ref={register({ required: true })}
            />
            {errors.trainingEnvironment && (
              <div className="error-form">Training Environment is required*</div>
            )}
          </div>

          <div className="fields-textarea">
            <div className="form-label">Specific Target Behavior</div>
            <textarea
              className="goal"
              name="specificTargetBehavior"
              ref={register({ required: true })}
            />
            {errors.specificTargetBehavior && (
              <div className="error-form">Specific Target Behavior is required*</div>
            )}
          </div>

          <div className="fields-textarea">
            <div className="form-label">Baseline Prompt Level</div>
            <textarea
              className="goal"
              name="baselinePromptLevel"
              ref={register({ required: true })}
            />
            {errors.baselinePromptLevel && (
              <div className="error-form">Baseline Prompt Level is required*</div>
            )}
          </div>

          <div className="fields-textarea">
            <div className="form-label">Notes</div>
            <textarea className="goal" name="notes" ref={register({ required: true })} />
            {errors.notes && <div className="error-form">Notes*</div>}
          </div>

          <div className="fields-textarea">
            <div className="form-label">Verb</div>
            <textarea className="goal" name="verb" ref={register({ required: true })} />
            {errors.verb && <div className="error-form">Verb*</div>}
          </div>

          <div className="form-buttons plan-buttons goal">
            <button className="home-save-form-button" type="submit">
              Save
            </button>
            <button
              className="home-back-form-button"
              type="button"
              onClick={() => history.push(`/homes/${id}/plan/${planId}/client/${clientId}/goals`)}
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditGoal;
