import { useMutation, MutationTuple } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { USER_CREATE } from '../../../graphql/mutations';
import { USERS } from '../../../graphql/queries';
import { UserCreateType, UserCreateVariablesType, UsersType } from '../../../graphql/types';

export const useUserCreate = (): MutationTuple<UserCreateType, UserCreateVariablesType> => {
  const history = useHistory();
  const { adminId } = useParams();
  const mutation = useMutation<UserCreateType, UserCreateVariablesType>(USER_CREATE, {
    update: (cache, data) => {
      const result = data.data?.userCreate;
      const query = cache.readQuery<UsersType>({ query: USERS });
      if (result && query) {
        const { edges } = query.users;
        cache.writeQuery<UsersType>({
          query: USERS,
          data: {
            ...query,
            users: {
              ...query.users,
              edges: [...edges, { __typename: 'UserEdge', node: result }],
            },
          },
        });
      }
      history.push(`/users/${adminId}`);
    },
  });

  return mutation;
};
