import { gql } from '@apollo/client';

export const GOAL_CREATE = gql`
  mutation GoalCreate($planId: ID!, $data: CreateGoalInput!) {
    goalCreate(planId: $planId, data: $data) {
      trainingEnvironment
      target
      specificTargetBehavior
      baselinePromptLevel
      notes
      verb
    }
  }
`;

export const GOAL_UPDATE = gql`
  mutation GoalUpdate($id: ID!, $data: CreateGoalInput!) {
    goalUpdate(id: $id, data: $data) {
      id
      trainingEnvironment
      target
      specificTargetBehavior
      baselinePromptLevel
      notes
      verb
    }
  }
`;

export const GOAL_DELETE = gql`
  mutation GoalDelete($id: ID!) {
    goalDelete(id: $id) {
      id
    }
  }
`;
