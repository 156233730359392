import { MutationTuple, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { SIGNATURE_SAVES_ETTINGS } from '../../../graphql/mutations';
import { SignatureCreateType, SignatureCreateVariablesType } from '../../../graphql/types';

export const useSignatureCreate = (): MutationTuple<
  SignatureCreateType,
  SignatureCreateVariablesType
> => {
  const history = useHistory();
  const mutation = useMutation<SignatureCreateType, SignatureCreateVariablesType>(
    SIGNATURE_SAVES_ETTINGS,
    {
      update: () => {
        history.go(0);
      },
    }
  );

  return mutation;
};
