import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useClientCreate } from './lib';

import '../../style/forms.scss';

interface Inputs {
  name: string;
  caregiver: string;
}

const NewClient: React.FC = () => {
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm<Inputs>();
  const [clientCreate] = useClientCreate();
  const history = useHistory();

  const onSubmit = (values: Inputs) => {
    clientCreate({
      variables: { homeId: id, data: values },
    });
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit(onSubmit)} className="new-edit-form">
        <span className="form-header">New Client</span>
        <div className="fields">
          <div className="form-label">Name</div>
          <input type="text" name="name" ref={register({ required: true })} />
          {errors.name && <div className="error-form">Name is required*</div>}
        </div>
        <div className="fields">
          <div className="form-label">Legal Guardian</div>
          <input type="text" name="caregiver" ref={register({ required: true })} />
          {errors.caregiver && <div className="error-form">Legal Guardian is required*</div>}
        </div>

        <div className="form-buttons">
          <button className="home-save-form-button" type="submit">
            Save
          </button>
          <button className="home-back-form-button" type="button" onClick={() => history.goBack()}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewClient;
