import { gql } from '@apollo/client';

export const STO_COUNTERS = gql`
  query StoCounters($stoId: ID!) {
    stoCounters(stoId: $stoId) {
      edges {
        node {
          id
          date
          data
        }
      }
    }
  }
`;

export const STO_COUNTER = gql`
  query StoCounter($id: ID!) {
    stoCounter(id: $id) {
      id
      date
      data
    }
  }
`;
