import { gql } from '@apollo/client';

export const PLANS = gql`
  query Plans($clientId: ID!) {
    plans(clientId: $clientId) {
      edges {
        node {
          id
          index
          startDate
          endDate
          supportDate
          effectiveDate
          rationalJustification
          assessmentTools
          stimulusPreferenceAssessment
          staffTrainers
          teachingStrategies
          dataCollectionSystem
          monitoringPlan
          goals {
            edges {
              node {
                id
                target
                specificTargetBehavior
                baselinePromptLevel
                trainingEnvironment
                notes
                verb
                deleted
                stos {
                  edges {
                    node {
                      id
                      index
                      startDate
                      endDate
                      promptLevel
                      criteria
                      frequency
                      deleted
                      step
                      stoCounters {
                        edges {
                          node {
                            id
                            date
                            data
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PLAN = gql`
  query Plan($id: ID!) {
    plan(id: $id) {
      id
      index
      startDate
      endDate
      supportDate
      effectiveDate
      rationalJustification
      assessmentTools
      stimulusPreferenceAssessment
      staffTrainers
      teachingStrategies
      dataCollectionSystem
      monitoringPlan
      goals {
        edges {
          node {
            id
            target
            specificTargetBehavior
            baselinePromptLevel
            trainingEnvironment
            notes
            verb
            deleted
            stos {
              edges {
                node {
                  id
                  index
                  startDate
                  endDate
                  promptLevel
                  criteria
                  frequency
                  deleted
                  step
                  stoCounters {
                    edges {
                      node {
                        id
                        date
                        data
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
