import { MutationTuple, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { PLAN_CREATE } from '../../../graphql/mutations';
import { PLANS } from '../../../graphql/queries';
import { PlanCreateType, PlanCreateVariablesType, PlansType } from '../../../graphql/types';

export const usePlanCreate = (): MutationTuple<PlanCreateType, PlanCreateVariablesType> => {
  const history = useHistory();
  const { id } = useParams();
  const mutation = useMutation<PlanCreateType, PlanCreateVariablesType>(PLAN_CREATE, {
    update: (cache, data) => {
      const result = data.data?.planCreate;
      const query = cache.readQuery<PlansType>({
        variables: { clientId: id },
        query: PLANS,
      });
      if (result && query) {
        const { edges } = query.plans;
        cache.writeQuery<PlansType>({
          variables: { clientId: id },
          query: PLANS,
          data: {
            ...query,
            plans: {
              ...query.plans,
              edges: [...edges, { __typename: 'PlanEdge', node: result }],
            },
          },
        });
      }
      history.goBack();
    },
  });

  return mutation;
};
