import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { BiPencil, BiTrash, BiBullseye } from 'react-icons/bi';
import swal from 'sweetalert';
import { GOALS, PLAN } from '../../graphql/queries';
import { GOAL_DELETE } from '../../graphql/mutations';
import { GoalsType, GoalDeleteType, GoalDeleteVariablesType, PlanType } from '../../graphql/types';
import Loading from '../Loading';

import '../style/client.scss';

const GoalList: React.FC = () => {
  const { id } = useParams();
  const { clientId, planId } = useParams();
  const history = useHistory();
  const { loading, data } = useQuery<GoalsType>(GOALS, {
    variables: { planId },
  });

  const [goalDeleted] = useMutation<GoalDeleteType, GoalDeleteVariablesType>(GOAL_DELETE, {
    variables: { id },
    update: (cache, goal) => {
      const result = goal.data?.goalDelete;
      const query = cache.readQuery<GoalsType>({
        variables: { planId },
        query: GOALS,
      });

      const planQuery = cache.readQuery<PlanType>({
        variables: { id: planId },
        query: PLAN,
      });

      if (result && query && planQuery) {
        const filtered = query?.goals.edges.filter(({ node }) => node.id !== result.id) || [];
        cache.writeQuery<GoalsType>({
          variables: { planId },
          query: GOALS,
          data: {
            ...query,
            goals: {
              ...query?.goals,
              edges: filtered,
            },
          },
        });

        let newEdges = JSON.parse(JSON.stringify(planQuery.plan.goals.edges));
        newEdges = filtered;

        cache.writeQuery<PlanType>({
          variables: { id: planId },
          query: PLAN,
          data: {
            ...planQuery,
            plan: {
              ...planQuery.plan,
              goals: {
                ...planQuery.plan.goals,
                edges: [...newEdges],
              },
            },
          },
        });

        history.push(`/homes/${id}/plan/${planId}/client/${clientId}/goals`);
      }
    },
  });

  const removeGoal = (node: any) => {
    swal({
      title: 'DELETE GOAL',
      text: `This action will remove the GOAL(${node.target}), you will not be able to recover this goal information.`,
      icon: 'error',
      buttons: ['Cancel', 'Delete Goal'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        goalDeleted({ variables: { id: node.id } });
      }
    });
  };

  if (loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="goals-content">
      <span className="goal-header">Goals</span>
      {data &&
        data.goals.edges.map(({ node }) => (
          <div key={node.id} className="goal">
            <span>{node.target}</span>

            <span className="action-remove">
              <BiTrash onClick={() => removeGoal(node)} />
            </span>

            <span className="action-sto">
              <BiBullseye
                onClick={() =>
                  history.push(
                    `/homes/${id}/plan/${planId}/client/${clientId}/goal/${node.id}/stos`
                  )
                }
              />
            </span>

            <span className="action-edit">
              <BiPencil
                onClick={() =>
                  history.push(
                    `/homes/${id}/plan/${planId}/client/${clientId}/goals/${node.id}/edit`
                  )
                }
              />
            </span>
          </div>
        ))}
    </div>
  );
};

export default GoalList;
