import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { USER } from '../../../graphql/queries';
import { USER_UPDATE } from '../../../graphql/mutations';
import Loading from '../../Loading';
import {
  UserUpdateType,
  UserUpdateVariablesType,
  UserType,
  UserVariablesType,
} from '../../../graphql/types';

import '../../style/forms.scss';
import '../../style/buttons.scss';

interface Inputs {
  email: string;
  name: string;
  admin: boolean;
}

const EditUser: React.FC = () => {
  const history = useHistory();
  const { id, adminId } = useParams();
  const { register, handleSubmit, errors, reset } = useForm<Inputs>();
  const { loading, data } = useQuery<UserType, UserVariablesType>(USER, {
    variables: { id },
    onCompleted: ({ user: { email, name, admin } }) => reset({ email, name, admin }),
  });
  const [homeUpdate] = useMutation<UserUpdateType, UserUpdateVariablesType>(USER_UPDATE, {
    onCompleted: () => history.push(`/users/${adminId}`),
  });

  const onSubmit = (values: Inputs) => {
    homeUpdate({ variables: { id, data: values } });
  };

  if (loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="form-container">
      {data && (
        <form onSubmit={handleSubmit(onSubmit)} className="new-edit-form">
          <span className="form-header">Edit User</span>
          <div className="fields">
            <div className="form-label">Email</div>
            <input type="text" name="email" ref={register({ required: true })} />
            {errors.email && <div className="error-form">Email is required*</div>}
          </div>

          <div className="fields">
            <div className="form-label">Name</div>
            <input type="text" name="name" ref={register({ required: true })} />
            {errors.name && <div className="error-form">Name is required*</div>}
          </div>

          <div className="fields-admin">
            <div className="form-label">Admin</div>
            <input type="checkbox" name="admin" ref={register({ required: false })} />
          </div>

          <div className="form-buttons">
            <button className="home-save-form-button" type="submit">
              Save
            </button>
            <button
              className="home-back-form-button"
              type="button"
              onClick={() => history.push(`/users/${adminId}`)}
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditUser;
