import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import Table from 'react-bootstrap/Table';
import { Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import { CLIENT, HOME, PLAN, SIGNATURE_SETTING } from '../../../graphql/queries';
import {
  ClientType,
  ClientVariablesType,
  HomeType,
  HomeVariablesType,
  PlanType,
  PlanVariablesType,
  SignatureType,
  SignatureVariableType,
} from '../../../graphql/types';
import Loading from '../../Loading';
import '../../style/page.scss';
import {
  getEdgesFromQuarter,
  getGraphData,
  getGraphOptions,
  getMonthPercent,
  getMonths,
} from '../PlanService';

const PlanReport: React.FC = () => {
  const { id, planId, clientId, selectedQuarter } = useParams();
  const { loading, data } = useQuery<PlanType, PlanVariablesType>(PLAN, {
    variables: { id: planId },
  });
  const clientQuery = useQuery<ClientType, ClientVariablesType>(CLIENT, {
    variables: { id: clientId },
  });
  const homeQuery = useQuery<HomeType, HomeVariablesType>(HOME, {
    variables: { id },
  });
  const SignatureQuery = useQuery<SignatureType, SignatureVariableType>(SIGNATURE_SETTING);

  let stoAcumulate = 0;
  let stoTotalAcumulate = 0;
  let indexAcumulate = 0;
  let pages: any[] = [];
  let graphData: any = {};

  const mapWord: any = {
    1: 'first',
    2: 'second',
    3: 'third',
    4: 'fourth',
    5: 'fifth',
    6: 'sixth',
    7: 'seventh',
    8: 'eighth',
    9: 'ninth',
    10: 'tenth',
    11: 'eleventh',
    12: 'twelfth',
    13: 'thirteenth',
    14: 'fourteenth',
    15: 'fifteenth',
    16: 'sixteenth',
  };

  const showStoInfoTable = (sto: any, quarter: number, last: boolean) => {
    const months = getMonths(sto.node, true);
    let completed = 0;

    if (quarter === 3) {
      stoTotalAcumulate += months.length;
    }

    return (
      <div key={sto.node.id}>
        {months.map((m: any, index: any) => {
          const percent = getMonthPercent(sto.node, m);
          const hasMet = parseFloat(percent) >= parseFloat(sto.node.criteria);

          if (hasMet) {
            completed += 1;
            stoAcumulate += 1;
          }

          if (quarter === 3) {
            indexAcumulate += 1;
          } else {
            indexAcumulate = index + 1;
          }

          return (
            <div key={m}>
              During the {mapWord[indexAcumulate]} month of this quater consumer completed {percent}
              % at a {sto.node.promptLevel} level, with a criteria of {sto.node.criteria}%. This
              goal has {hasMet ? '' : 'not '}
              been met.
            </div>
          );
        })}
        {quarter !== 3 || last ? (
          <div key={indexAcumulate}>
            {quarter === 3
              ? `Up to date in ${stoAcumulate}/`
              : `For this quarter period ${completed}/`}
            {quarter === 3 ? `${stoTotalAcumulate} months ` : `${months.length} of `} the STO have{' '}
            {completed > 1 || stoAcumulate > 4 ? ' been ' : ' not been '}
            met. At the end of each month during this quarter, the data was reviewed to determine if
            criteria for each short term objetive for that month was met. The short term objectives
            are revised by adjusting criteria and/or adjusting the prompt level or changing task
            based on that month&apos;s performance. <br />
            Recomendation: This goal will continue, adding additional tasks from the task analysis
            into the next quater to promote skill maintenance and generalization.
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  const showGoaldsTable = (goal: any, qty: number, quarter: number) => {
    const { start, end } = getEdgesFromQuarter(quarter);
    const stosQuarter = goal.node.stos.edges.slice(start, end);
    stoAcumulate = 0;
    stoTotalAcumulate = 0;
    indexAcumulate = 0;

    return (
      <Table
        bordered
        key={goal.node.id}
        className={`goal-info-report ${quarter === 3 ? 'one-page' : ''}`}
      >
        <tbody>
          <tr>
            <td>
              Goal #{qty + 1}: {goal.node.target}
            </td>
          </tr>
          <tr>
            <td>
              {stosQuarter.map((s: any, index: any) => {
                return showStoInfoTable(s, quarter, index === stosQuarter.length - 1);
              })}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const footer = () => {
    return (
      <table className="sign-report-footer">
        <tbody>
          <tr>
            <td className="sign-report-container" colSpan={4}>
              <div>Report written by:</div>
              {SignatureQuery?.data?.signature?.signature ? (
                <img
                  alt="signature"
                  className="sigImage"
                  src={SignatureQuery?.data?.signature?.signature}
                />
              ) : null}
            </td>
          </tr>
          <tr>
            <td className="sign-field"> Douglas Perez, MPA, BCBA</td>
            <td className="no-border" />
            <td className="sign-field">Date</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const getHeaderText = (quarter: number) => {
    const sufixMap: any = {
      1: 'st',
      2: 'nd',
      3: 'rd',
      4: 'th',
    };

    if (quarter !== 3) {
      return `Summary of ${quarter}${sufixMap[quarter]} Quarter`;
    }

    return `${quarter}${sufixMap[quarter]} Quarter Report. Annaual Summary`;
  };

  const showPages = (p: any, index: number, quarter: number) => {
    let indexNew = index;
    if (quarter !== 3) {
      indexNew = index * 3;
    }

    return (
      <div className="page" key={index}>
        <Table bordered className="goal-table-report">
          <thead>
            <tr>
              <th className="report-header" colSpan={3}>
                RESIDENTIAL HABILITATION QUARTERLY DATA ANALYSIS AND SUMARY OF PROGESS NOTES
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={3}>
                Report from: {graphData?.fromDate} To:{' '}
                {graphData?.labels[graphData?.labels.length - 1]}
              </td>
            </tr>
            <tr>
              <td>Consumer: {clientQuery?.data?.client.name}</td>
              <td>
                IP Efective Date from:{' '}
                {isEmpty(data?.plan.effectiveDate)
                  ? ''
                  : moment(data?.plan.effectiveDate).format('M/D/YYYY')}
              </td>
              <td>Location: {homeQuery?.data?.home.name}</td>
            </tr>
          </tbody>
        </Table>
        <div className="summary">
          <span>
            <b>{getHeaderText(quarter)}</b>
          </span>
        </div>
        <div className="goal-info-container">
          {p.map((g: any, i: any) => {
            return showGoaldsTable(g, indexNew + i, quarter);
          })}
        </div>
      </div>
    );
  };

  const gatherGoalsRender = (quarter: number) => {
    const nGoals = data?.plan.goals.edges.length || 0;
    let insidePages: any[] = [];
    pages = [];

    for (let i = 0; i < nGoals; i += 1) {
      const goal = data?.plan.goals.edges[i];
      insidePages.push(goal);
      if (quarter !== 3) {
        if (i !== 0 && (i + 1) % 3 === 0) {
          pages.push(insidePages);
          insidePages = [];
        } else if (i === nGoals - 1) {
          pages.push(insidePages);
        }
      } else {
        pages.push(insidePages);
        insidePages = [];
      }
    }
  };

  const gatherGraficRender = () => {
    const graphPages: any[] = [];
    let content = [];
    const nGraph = graphData.datasets.length;
    for (let i = 0; i < nGraph; i += 1) {
      const newGraph = {
        labels: graphData.labels,
        datasets: [graphData.datasets[i]],
      };
      content.push(newGraph);
      if (i !== 0 && (i + 1) % 2 === 0) {
        graphPages.push(content);
        content = [];
      } else if (i === nGraph - 1) {
        graphPages.push(content);
      }
    }

    return graphPages;
  };

  gatherGoalsRender(parseInt(selectedQuarter, 10));

  if (
    !data ||
    loading ||
    !clientQuery.data ||
    clientQuery.loading ||
    !homeQuery.data ||
    homeQuery.loading
  )
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  graphData = getGraphData(data?.plan, parseInt(selectedQuarter, 10));
  const graphPages = gatherGraficRender();

  return (
    <div className="pages-container">
      <div className="buttons">
        <button
          className="add-button plan-botton-back dispose"
          type="button"
          onClick={() => window.close()}
        >
          Close
        </button>
        <button
          className="add-button plan-botton-back print"
          type="button"
          onClick={() => window.print()}
        >
          Print
        </button>
      </div>

      {pages.map((p, index) => {
        return showPages(p, index, parseInt(selectedQuarter, 10));
      })}

      {graphPages.map((g: any, index: number) => {
        return (
          <div className="page">
            {g.map((newGraph: any) => {
              return (
                <div>
                  <Line type="line" data={newGraph} options={getGraphOptions()} />
                </div>
              );
            })}
            {index === graphPages.length - 1 ? <div>{footer()}</div> : null}
          </div>
        );
      })}
    </div>
  );
};

export default PlanReport;
