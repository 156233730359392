import { gql } from '@apollo/client';

export const HOMES = gql`
  query Homes($userId: ID!) {
    homes(userId: $userId) {
      edges {
        node {
          id
          name
          address
          phone
          email
          contactName
          userHomes {
            edges {
              node {
                id
                userId
                homeId
                deleted
              }
            }
          }
        }
      }
    }
  }
`;

export const HOME = gql`
  query Home($id: ID!) {
    home(id: $id) {
      id
      name
      address
      phone
      email
      contactName
    }
  }
`;
