import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useUserCreate } from './lib';

import '../../style/buttons.scss';
import '../../style/home.scss';

interface Inputs {
  email: string;
  name: string;
  admin: boolean;
}

const NewUser: React.FC = () => {
  const history = useHistory();
  const { adminId } = useParams();
  const { register, handleSubmit, errors } = useForm<Inputs>();
  const [userCreate] = useUserCreate();

  const onSubmit = (values: Inputs) => {
    userCreate({
      variables: { data: values },
    });
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit(onSubmit)} className="new-edit-form">
        <span className="form-header">New User</span>
        <div className="fields">
          <div className="form-label">Email</div>
          <input type="text" name="email" ref={register({ required: true })} />
          {errors.email && <div className="error-form">Email is required*</div>}
        </div>

        <div className="fields">
          <div className="form-label">Name</div>
          <input type="text" name="name" ref={register({ required: true })} />
          {errors.name && <div className="error-form">Name is required*</div>}
        </div>

        <div className="fields-admin">
          <div className="form-label">Admin</div>
          <input type="checkbox" name="admin" ref={register({ required: false })} />
        </div>

        <div className="form-buttons">
          <button className="home-save-form-button" type="submit">
            Save
          </button>
          <button
            className="home-back-form-button"
            type="button"
            onClick={() => history.push(`/users/${adminId}`)}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewUser;
