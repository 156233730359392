import { gql } from '@apollo/client';

export const PLAN_CREATE = gql`
  mutation PlanCreate($clientId: ID!, $data: CreatePlanInput!) {
    planCreate(clientId: $clientId, data: $data) {
      startDate
      endDate
      supportDate
      effectiveDate
      rationalJustification
      assessmentTools
      stimulusPreferenceAssessment
      staffTrainers
      teachingStrategies
      dataCollectionSystem
      monitoringPlan
    }
  }
`;

export const PLAN_UPDATE = gql`
  mutation PlanUpdate($id: ID!, $data: CreatePlanInput!) {
    planUpdate(id: $id, data: $data) {
      id
      startDate
      endDate
      supportDate
      effectiveDate
      rationalJustification
      assessmentTools
      stimulusPreferenceAssessment
      staffTrainers
      teachingStrategies
      dataCollectionSystem
      monitoringPlan
    }
  }
`;

export const PLAN_DELETE = gql`
  mutation PlanDelete($id: ID!) {
    planDelete(id: $id) {
      id
    }
  }
`;
