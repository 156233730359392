import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { CLIENT } from '../../../graphql/queries';
import { CLIENT_UPDATE } from '../../../graphql/mutations';
import {
  ClientUpdateType,
  ClientUpdateVariablesType,
  ClientType,
  ClientVariablesType,
} from '../../../graphql/types';
import Loading from '../../Loading';

interface Inputs {
  name: string;
  caregiver: string;
}

const EditClient: React.FC = () => {
  const history = useHistory();
  const { id } = useParams();
  const { register, handleSubmit, errors, reset } = useForm<Inputs>();
  const { loading, data } = useQuery<ClientType, ClientVariablesType>(CLIENT, {
    variables: { id },
    onCompleted: ({ client: { name, caregiver } }) => reset({ name, caregiver }),
  });
  const [clientUpdate] = useMutation<ClientUpdateType, ClientUpdateVariablesType>(CLIENT_UPDATE, {
    onCompleted: () => history.goBack(),
  });

  const onSubmit = (values: Inputs) => {
    clientUpdate({ variables: { id, data: values } });
  };

  if (loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="form-container">
      {data && (
        <form onSubmit={handleSubmit(onSubmit)} className="new-edit-form">
          <span className="form-header">Edit Client</span>
          <div className="fields">
            <div className="form-label">Name</div>
            <input type="text" name="name" ref={register({ required: true })} />
            {errors.name && <div className="error-form">Name is required*</div>}
          </div>
          <div className="fields">
            <div className="form-label">Legal Guardian</div>
            <input type="text" name="caregiver" ref={register({ required: true })} />
            {errors.caregiver && <div className="error-form">Legal Guardian is required*</div>}
          </div>

          <div className="form-buttons">
            <button className="home-save-form-button" type="submit">
              Save
            </button>
            <button
              className="home-back-form-button"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditClient;
