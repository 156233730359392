import { useMutation, MutationTuple } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { GOAL_CREATE } from '../../../graphql/mutations';
import { GOALS, PLAN } from '../../../graphql/queries';
import {
  GoalCreateType,
  GoalCreateVariablesType,
  GoalsType,
  PlanType,
} from '../../../graphql/types';

export const useGoalCreate = (): MutationTuple<GoalCreateType, GoalCreateVariablesType> => {
  const history = useHistory();
  const { id, planId, clientId } = useParams();
  const mutation = useMutation<GoalCreateType, GoalCreateVariablesType>(GOAL_CREATE, {
    update: (cache, data) => {
      const result = data.data?.goalCreate;
      const query = cache.readQuery<GoalsType>({
        variables: { planId },
        query: GOALS,
      });

      const planQuery = cache.readQuery<PlanType>({
        variables: { id: planId },
        query: PLAN,
      });

      if (result && query && planQuery) {
        const { edges } = query.goals;
        cache.writeQuery<GoalsType>({
          variables: { planId },
          query: GOALS,
          data: {
            ...query,
            goals: {
              ...query.goals,
              edges: [...edges, { __typename: 'GoalEdge', node: result }],
            },
          },
        });

        let newEdges = JSON.parse(JSON.stringify(planQuery.plan.goals.edges));
        newEdges = [...newEdges, { __typename: 'GoalEdge', node: result }];

        cache.writeQuery<PlanType>({
          variables: { id: planId },
          query: PLAN,
          data: {
            ...planQuery,
            plan: {
              ...planQuery.plan,
              goals: {
                ...planQuery.plan.goals,
                edges: [...newEdges],
              },
            },
          },
        });

        history.push(`/homes/${id}/plan/${planId}/client/${clientId}/goals`);
      }
    },
  });

  return mutation;
};
