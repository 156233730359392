import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { BiPencil, BiTrash } from 'react-icons/bi';
import swal from 'sweetalert';
import { STOS, PLAN } from '../../graphql/queries';
import { STO_DELETE } from '../../graphql/mutations';
import { StosType, StoDeleteType, StoDeleteVariablesType, PlanType } from '../../graphql/types';
import Loading from '../Loading';

import '../style/client.scss';

const StoList: React.FC = () => {
  const { id } = useParams();
  const { clientId, planId, goalId } = useParams();
  const history = useHistory();
  const { loading, data } = useQuery<StosType>(STOS, {
    variables: { goalId },
  });

  const [stoDeleted] = useMutation<StoDeleteType, StoDeleteVariablesType>(STO_DELETE, {
    variables: { id },
    update: (cache, sto) => {
      const result = sto.data?.stoDelete;
      const query = cache.readQuery<StosType>({
        variables: { goalId },
        query: STOS,
      });

      const planQuery = cache.readQuery<PlanType>({
        variables: { id: planId },
        query: PLAN,
      });

      if (result && query && planQuery) {
        const filtered = query?.stos.edges.filter(({ node }) => node.id !== result.id) || [];
        cache.writeQuery<StosType>({
          variables: { goalId },
          query: STOS,
          data: {
            ...query,
            stos: {
              ...query?.stos,
              edges: filtered,
            },
          },
        });

        const newEdges = JSON.parse(JSON.stringify(planQuery.plan.goals.edges));
        const goalsSelected = newEdges.filter((g: any) => {
          return g.node.id === goalId;
        });
        goalsSelected[0].node.stos.edges = filtered;

        cache.writeQuery<PlanType>({
          variables: { id: planId },
          query: PLAN,
          data: {
            ...planQuery,
            plan: {
              ...planQuery.plan,
              goals: {
                ...planQuery.plan.goals,
                edges: [...newEdges],
              },
            },
          },
        });

        history.push(`/homes/${id}/plan/${planId}/client/${clientId}/goal/${goalId}/stos`);
      }
    },
  });

  const removeSto = (node: any) => {
    swal({
      title: 'DELETE STO',
      text: `This action will remove the STO #(${node.index}), you will not be able to recover this sto information.`,
      icon: 'error',
      buttons: ['Cancel', 'Delete Sto'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        stoDeleted({ variables: { id: node.id } });
      }
    });
  };

  if (loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="goals-content">
      <span className="goal-header">Stos</span>
      {data &&
        data.stos.edges.map(({ node }) => (
          <div key={node.id} className="goal">
            <span>Sto #{node.index}</span>

            <span className="action-remove">
              <BiTrash onClick={() => removeSto(node)} />
            </span>

            <span className="action-edit">
              <BiPencil
                onClick={() =>
                  history.push(
                    `/homes/${id}/plan/${planId}/client/${clientId}/goal/${goalId}/stos/${node.id}/edit`
                  )
                }
              />
            </span>
          </div>
        ))}
    </div>
  );
};

export default StoList;
