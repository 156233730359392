import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { PLAN_UPDATE } from '../../../graphql/mutations';
import { PLAN } from '../../../graphql/queries';
import {
  PlanType,
  PlanUpdateType,
  PlanUpdateVariablesType,
  PlanVariablesType,
} from '../../../graphql/types';

import Loading from '../../Loading';
import '../../style/forms.scss';

interface Inputs {
  index: number;
  startDate: Date;
  endDate: Date;
  supportDate: Date;
  effectiveDate: Date;
  rationalJustification: string;
  assessmentTools: string;
  stimulusPreferenceAssessment: string;
  staffTrainers: string;
  teachingStrategies: string;
  dataCollectionSystem: string;
  monitoringPlan: string;
}

const EditPlan: React.FC = () => {
  const history = useHistory();
  const { id } = useParams();
  const { register, handleSubmit, errors, reset } = useForm<Inputs>();
  const { loading, data } = useQuery<PlanType, PlanVariablesType>(PLAN, {
    variables: { id },
    onCompleted: ({
      plan: {
        index,
        startDate,
        endDate,
        supportDate,
        effectiveDate,
        rationalJustification,
        assessmentTools,
        stimulusPreferenceAssessment,
        staffTrainers,
        teachingStrategies,
        dataCollectionSystem,
        monitoringPlan,
      },
    }) =>
      reset({
        index,
        startDate,
        endDate,
        supportDate,
        effectiveDate,
        rationalJustification,
        assessmentTools,
        stimulusPreferenceAssessment,
        staffTrainers,
        teachingStrategies,
        dataCollectionSystem,
        monitoringPlan,
      }),
  });
  const [planUpdate] = useMutation<PlanUpdateType, PlanUpdateVariablesType>(PLAN_UPDATE, {
    onCompleted: () => history.goBack(),
  });

  const onSubmit = (values: Inputs) => {
    planUpdate({ variables: { id, data: values } });
  };

  if (loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="form-container plan-form">
      {data && (
        <form onSubmit={handleSubmit(onSubmit)} className="new-edit-form plan-form">
          <span className="form-header">Edit Plan</span>

          <div className="fields">
            <div className="form-label">Start Date</div>
            <input type="date" name="startDate" ref={register({ required: true })} />
            {errors.startDate && <div className="error-form">Start Date is required*</div>}
          </div>

          <div className="fields">
            <div className="form-label">End Date</div>
            <input type="date" name="endDate" ref={register({ required: true })} />
            {errors.endDate && <div className="error-form">End Date is required*</div>}
          </div>

          <div className="fields">
            <div className="form-label">IP Effective Date</div>
            <input type="date" name="effectiveDate" ref={register({ required: true })} />
            {errors.effectiveDate && (
              <div className="error-form">IP Effective Date is required*</div>
            )}
          </div>

          <div className="fields">
            <div className="form-label">Support Date</div>
            <input type="date" name="supportDate" ref={register({ required: true })} />
            {errors.supportDate && <div className="error-form">Support Date is required*</div>}
          </div>

          <div className="fields-textarea">
            <div className="form-label">Staff Trainers</div>
            <textarea name="staffTrainers" ref={register({ required: true })} />
            {errors.staffTrainers && <div className="error-form">Staff Trainers is required*</div>}
          </div>

          <div className="fields-textarea">
            <div className="form-label">Rational Justification</div>
            <textarea name="rationalJustification" ref={register({ required: true })} />
            {errors.rationalJustification && (
              <div className="error-form">Rational Justification is required*</div>
            )}
          </div>

          <div className="fields-textarea">
            <div className="form-label">Assessment Tools</div>
            <textarea name="assessmentTools" ref={register({ required: true })} />
            {errors.assessmentTools && (
              <div className="error-form">Assessment Tools is required*</div>
            )}
          </div>

          <div className="fields-textarea">
            <div className="form-label">Stimulus Reference Assessment</div>
            <textarea name="stimulusPreferenceAssessment" ref={register({ required: true })} />
            {errors.stimulusPreferenceAssessment && (
              <div className="error-form">Stimulus Preference Assessment is required*</div>
            )}
          </div>

          <div className="fields-textarea">
            <div className="form-label">Teaching Strategies</div>
            <textarea name="teachingStrategies" ref={register({ required: true })} />
            {errors.teachingStrategies && (
              <div className="error-form">Teaching Strategies is required*</div>
            )}
          </div>

          <div className="fields-textarea">
            <div className="form-label">Data Collection System</div>
            <textarea name="dataCollectionSystem" ref={register({ required: true })} />
            {errors.dataCollectionSystem && (
              <div className="error-form">Data Collection System is required*</div>
            )}
          </div>

          <div className="fields-textarea">
            <div className="form-label">Monitoring Plan</div>
            <textarea name="monitoringPlan" ref={register({ required: true })} />
            {errors.monitoringPlan && (
              <div className="error-form">Monitoring Plan is required*</div>
            )}
          </div>

          <div className="form-buttons plan-buttons">
            <button className="home-save-form-button" type="submit">
              Save
            </button>
            <button
              className="home-back-form-button"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditPlan;
