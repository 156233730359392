import { gql } from '@apollo/client';

export const STOS = gql`
  query Stos($goalId: ID!) {
    stos(goalId: $goalId) {
      edges {
        node {
          id
          index
          startDate
          endDate
          promptLevel
          criteria
          frequency
          step
        }
      }
    }
  }
`;

export const STO = gql`
  query Sto($id: ID!) {
    sto(id: $id) {
      id
      index
      startDate
      endDate
      promptLevel
      criteria
      frequency
      step
    }
  }
`;
