import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Route, useHistory, useParams } from 'react-router-dom';
import { HOME, USERS } from '../../../graphql/queries';
import { HomeType, HomeVariablesType, UsersType } from '../../../graphql/types';
import Clients from '../../Clients';
import Loading from '../../Loading';
import Plans from '../../Plans';
import { getUserByEmail } from '../../Users/UserService';

import '../../style/home.scss';

const Home: React.FC = () => {
  const { id, clientId } = useParams();
  const { loading, data } = useQuery<HomeType, HomeVariablesType>(HOME, {
    variables: { id },
  });
  const userData = useQuery<UsersType>(USERS);
  const { user } = useAuth0();
  const history = useHistory();
  const disablePlan = clientId === 'undefined';
  let currentUser: any = {};

  const getCurrentUser = () => {
    currentUser = getUserByEmail(userData.data?.users, user.name);
  };

  const renderHeader = () => {
    if (currentUser && currentUser.admin) {
      return (
        <div className="group-header">
          <span className="header">Home: {data?.home.name}</span>
          <button
            className="add-button left"
            type="button"
            onClick={() => history.push(`/homes/${id}/clients/new`)}
          >
            Add Client
          </button>
          <button
            className={`add-button right ${disablePlan ? 'disabled' : ''}`}
            disabled={disablePlan}
            type="button"
            onClick={() => history.push(`/clients/${clientId}/plans/new`)}
          >
            Add Plan
          </button>
        </div>
      );
    }
    return (
      <div className="group-header">
        <span className="header">Home: {data?.home.name}</span>
      </div>
    );
  };

  if (loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  if (!data || !userData.data) return null;

  getCurrentUser();

  return (
    <div className="home-view-content">
      {renderHeader()}

      <div className="client-list-container">
        <Clients currentUser={currentUser} />
      </div>
      <div className="plan-list-container">
        <Route component={Plans} path="/homes/:id/clients/:clientId" />
      </div>
    </div>
  );
};

export default Home;
