import { gql } from '@apollo/client';

export const GOALS = gql`
  query Goals($planId: ID!) {
    goals(planId: $planId) {
      edges {
        node {
          id
          trainingEnvironment
          target
          specificTargetBehavior
          baselinePromptLevel
          notes
          verb
        }
      }
    }
  }
`;

export const GOAL = gql`
  query Goal($id: ID!) {
    goal(id: $id) {
      id
      trainingEnvironment
      target
      specificTargetBehavior
      baselinePromptLevel
      notes
      verb
    }
  }
`;
