import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { USERS } from '../../graphql/queries';
import { UsersType } from '../../graphql/types';
import { getUserByEmail } from '../Users/UserService';
import HomesList from './HomesList';

import '../style/home.scss';
import '../style/buttons.scss';

const Homes: React.FC = () => {
  const history = useHistory();
  const { loading, data } = useQuery<UsersType>(USERS);
  const { user } = useAuth0();
  let currentUser: any = {};

  const getCurrentUser = () => {
    currentUser = getUserByEmail(data?.users, user.name);
  };

  const hideShowAddHome = () => {
    if (currentUser && currentUser.admin) {
      return (
        <button className="add-button" type="button" onClick={() => history.push('/homes/new')}>
          Add Group Home
        </button>
      );
    }

    return null;
  };

  if (!data || loading) return null;

  getCurrentUser();

  return (
    <div className="group-home-content">
      <div className="group-header">
        <span className="header">Group Homes</span>
        {hideShowAddHome()}
      </div>

      <HomesList currentUser={currentUser} />
    </div>
  );
};

export default Homes;
