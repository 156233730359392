import { gql } from '@apollo/client';

export const USERS = gql`
  query Users {
    users {
      edges {
        node {
          id
          email
          name
          admin
        }
      }
    }
  }
`;

export const USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      email
      name
      admin
    }
  }
`;
