import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useHistory, useParams } from 'react-router-dom';
import { Stos_stos, Stos_stos_edges_node } from '../../../graphql/generated/Stos';
import { STO_COUNTER_UPDATE } from '../../../graphql/mutations';
import { CLIENT, PLAN, USERS } from '../../../graphql/queries';
import {
  ClientType,
  ClientVariablesType,
  PlanType,
  PlanVariablesType,
  StoCounterUpdateType,
  StoCounterUpdateVariablesType,
  UsersType,
} from '../../../graphql/types';
import Loading from '../../Loading';
import { useStoCounterCreate } from '../../StoCounters/NewStoCounter/lib';
import { getUserByEmail } from '../../Users/UserService';
import '../../style/home.scss';
import { getDays, getMonthPercent, getMonths } from '../PlanService';

const PlanPrint: React.FC = () => {
  const { id, clientId, planId } = useParams();
  const init: Stos_stos = { __typename: 'StoConnection', edges: [] };
  const initSto: Stos_stos_edges_node = {
    __typename: 'Sto',
    id: '-1',
    index: -1,
    startDate: new Date(),
    endDate: new Date(),
    promptLevel: '',
    criteria: '',
    frequency: '',
    step: '',
    stoCounters: {
      __typename: 'StoCounterConnection',
      edges: [],
    },
  };

  const options = ['Abs', 'Yes', 'No'];
  const [stos, setStos] = useState(init);
  const [sto, setSto] = useState(initSto);
  const [goalId, setGoalId] = useState('');
  const [stoId, setStoId] = useState('');
  const first = useRef(true);
  const emptySto = useRef(false);
  const emptyGoal = useRef(false);
  const history = useHistory();
  const { loading, data } = useQuery<PlanType, PlanVariablesType>(PLAN, {
    variables: { id: planId },
  });

  const clientQuery = useQuery<ClientType, ClientVariablesType>(CLIENT, {
    variables: { id: clientId },
  });
  const userQuery = useQuery<UsersType>(USERS);

  const [stoCounterCreate] = useStoCounterCreate(stoId, goalId);
  const [stoCounterUpdate] = useMutation<StoCounterUpdateType, StoCounterUpdateVariablesType>(
    STO_COUNTER_UPDATE,
    {
      onCompleted: () => {
        history.push('/');
        history.push(`/homes/${id}/client/${clientId}/plan/${planId}/show`);
      },
    }
  );
  let currentUser: any = null;
  const { user } = useAuth0();

  interface CounterInputs {
    date: string;
    data: string;
  }

  useEffect(() => {
    if (data && first.current) {
      first.current = false;
      let tempStoId = localStorage.getItem('stoId');
      let tempGoalId = localStorage.getItem('goalId');

      let tempGoal = data?.plan.goals.edges.filter((g) => {
        return g.node.id === tempGoalId;
      });

      if (isEmpty(tempGoal)) {
        tempGoal = [data?.plan.goals.edges[0]];
        setStos(data?.plan.goals.edges[0]?.node.stos);
        if (!data?.plan.goals.edges[0]) {
          emptyGoal.current = true;
          return;
        }
      } else {
        setStos(tempGoal[0].node.stos);
      }
      let tempSto = tempGoal[0].node.stos.edges.filter((s) => {
        return s.node.id === tempStoId;
      });

      if (isEmpty(tempSto)) {
        tempSto = [tempGoal[0].node.stos.edges[0]];
        if (!tempSto[0]) {
          emptySto.current = true;
        } else {
          setSto(tempSto[0].node);
        }
      } else {
        setSto(tempSto[0].node);
      }

      tempGoalId = tempGoal[0].node.id;
      tempStoId = tempSto[0]?.node.id;
      setGoalId(tempGoalId || '');
      setStoId(tempStoId || '');
      localStorage.setItem('stoId', tempStoId);
      localStorage.setItem('goalId', tempGoalId);
    }
  }, [data]);

  const handleGoalChange = (event: any) => {
    const selectedGoal = data?.plan.goals.edges.filter(
      ({ node }) => node.id === event.target.value
    );

    if (selectedGoal) {
      setStos(selectedGoal[0].node.stos);
      localStorage.setItem('goalId', selectedGoal[0].node.id);
      setGoalId(selectedGoal[0].node.id);
      if (selectedGoal[0].node.stos.edges[0]) {
        setSto(selectedGoal[0].node.stos.edges[0].node);
        localStorage.setItem('stoId', selectedGoal[0].node.stos.edges[0].node.id);
        setStoId(selectedGoal[0].node.stos.edges[0].node.id);
        emptySto.current = false;
      } else {
        localStorage.setItem('stoId', '');
        emptySto.current = true;
      }
    }
  };

  const handleStoChange = (event: any) => {
    const selected = stos.edges.filter((s) => {
      return s.node.id === event.target.value;
    });

    setSto(selected[0].node);
    localStorage.setItem('stoId', selected[0].node.id);
    setStoId(selected[0].node.id);
  };

  const getYear = (m: any) => {
    const { startDate, endDate } = sto;
    let temp = moment(startDate);

    while (temp.format('YYYY-M') <= moment(endDate).format('YYYY-M')) {
      if (parseInt(temp.format('M'), 10) === m + 1) {
        return temp.year();
      }
      temp = temp.add(1, 'month');
    }

    return temp.year();
  };

  const getStoCounter = (date: string) => {
    const counter = sto.stoCounters.edges.filter(({ node }) => {
      return moment(node.date).format('YYYY-MM-DD') === date;
    });

    return counter[0];
  };

  const handleOptionChange = (event: any, d: any, m: any) => {
    const year = getYear(m);
    const date = moment().year(year).month(m).date(d).format('YYYY-MM-DD');
    const values: CounterInputs = {
      date,
      data: event.target.value,
    };

    const counter = getStoCounter(date);

    if (isEmpty(counter)) {
      stoCounterCreate({
        variables: { stoId: sto.id, data: values },
      });
    } else {
      stoCounterUpdate({ variables: { id: counter.node.id, data: values } });
    }
  };

  const getDefaultValues = (m: any, d: any) => {
    const year = getYear(m);
    const date = moment().year(year).month(m).date(d).format('YYYY-MM-DD');
    const counter = getStoCounter(date);

    if (isEmpty(counter)) {
      return 'Abs';
    }

    return counter.node.data;
  };

  const getGoal = () => {
    const tempGoal = data?.plan.goals.edges.filter((g) => {
      return g.node.id === goalId;
    });

    if (tempGoal) {
      return tempGoal[0];
    }

    return null;
  };

  const getBack = () => {
    if (emptyGoal.current) {
      history.push(`/homes/${id}/clients/${clientId}`);
      return;
    }

    const goalWithSto = data?.plan.goals.edges.filter((g) => {
      return g.node.stos.edges.length > 0;
    });

    if (isNil(goalWithSto) || isEmpty(goalWithSto)) {
      history.push(`/homes/${id}/clients/${clientId}`);
    } else {
      window.location.reload();
      localStorage.setItem('goalId', goalWithSto[0]?.node.id || '');
      localStorage.setItem('stoId', goalWithSto[0]?.node.stos.edges[0]?.node.id || '');
    }
  };

  const getButtonName = () => {
    if (emptyGoal.current) {
      return 'Insert Goal';
    }
    if (emptySto.current) {
      return 'Insert Sto';
    }

    return 'Error button';
  };

  const getText = () => {
    if (emptyGoal.current) {
      if (currentUser.admin) {
        return 'Please enter the Goals to continue.';
      }
      return 'Please contact the administrator to enter the goals in order to continue.';
    }
    if (emptySto.current) {
      if (currentUser.admin) {
        return `Please enter the Stos for the Goal selected (${
          getGoal()?.node.target
        }) to continue.`;
      }

      return 'Please contact the administrator to enter the Stos in order to continue.';
    }

    return 'Something went wrong!!!';
  };

  const getCurrentUser = () => {
    currentUser = getUserByEmail(userQuery.data?.users, user.name);
  };

  const disabledDataSelect = (m: number, d: number) => {
    if (currentUser.admin) {
      return false;
    }

    const year = getYear(m);
    const date = moment().year(year).month(m).date(d);
    const today = moment();

    return date > today;
  };

  const disableStoSelect = (stoSelectId: string, index: number) => {
    if (index === 1 || currentUser.admin) {
      return false;
    }

    const beforeIndex = index - 1;
    let counter = 0;

    const selected = stos.edges.filter((s) => {
      return s.node.index === beforeIndex;
    });

    getMonths(selected[0].node, true).map((m: any) => {
      const percent = getMonthPercent(selected[0].node, m);
      if (parseFloat(percent) >= parseFloat(selected[0].node.criteria)) {
        counter += 1;
      }
      return counter;
    });

    return counter < 2;
  };

  const printClick = () => {
    window
      .open(`/plans/${planId}/goal/${goalId}/sto/${stoId}/client/${clientId}/empty-sheet`, '_blank')
      ?.focus();
  };

  if (
    !data ||
    !clientQuery.data ||
    loading ||
    clientQuery.loading ||
    userQuery.loading ||
    !userQuery.data
  )
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  getCurrentUser();

  if (data && clientQuery.data && (emptySto.current || emptyGoal.current)) {
    return (
      <div className="group-plan-content">
        <div className="not-info">
          <span className="empty-text">{getText()}</span>
          <button
            className="add-button no-sto-back-button"
            type="button"
            onClick={() => {
              getBack();
            }}
          >
            Back
          </button>
          {currentUser && currentUser.admin ? (
            <button
              className="add-button no-sto-back-button left"
              type="button"
              onClick={() => {
                if (emptyGoal.current) {
                  history.push(`/homes/${id}/plan/${planId}/client/${clientId}/goals/new`);
                } else {
                  history.push(
                    `/homes/${id}/plan/${planId}/client/${clientId}/goal/${goalId}/stos/new`
                  );
                }
              }}
            >
              {getButtonName()}
            </button>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="plan-view-content">
      <div className="group-header">
        <span className="header">
          Client: {clientQuery?.data?.client.name} - Plan: {data.plan.index}
        </span>
        <button
          className="add-button plan-botton-back"
          type="button"
          onClick={() => history.push(`/homes/${id}/clients/${clientId}`)}
        >
          Back
        </button>
        <button className="add-button plan-botton-print" type="button" onClick={() => printClick()}>
          Print
        </button>

        <select onChange={handleGoalChange} value={goalId} className="goals-select left">
          {data.plan.goals.edges.map(({ node }) => (
            <option key={node.id} value={node.id}>
              {node.target}
            </option>
          ))}
        </select>

        <select onChange={handleStoChange} value={stoId} className="stos-select">
          {stos.edges.map(({ node }) => (
            <option key={node.id} value={node.id} disabled={disableStoSelect(node.id, node.index)}>
              Sto# {node.index}
            </option>
          ))}
        </select>
      </div>
      {getMonths(sto, !first.current).map((m: any) => (
        <Table striped bordered hover key={m}>
          <thead>
            <tr>
              <th className="remove-borders">Days of the Month =&gt; </th>
              {getDays(sto, m - 1).map((d) => (
                <th key={d}>{d}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>STO Month:</b>{' '}
                {moment()
                  .month(m - 1)
                  .format('MMM')}
                <br />
                <b>Prompt Level:</b> {sto.promptLevel} <br />
                <b>Criteria:</b> {sto.criteria}% <br />
                <b>Frecuency:</b> {sto.frequency} <br />
                <b>Steps: </b>
                {sto.step || 'All Steps'} <br />
                <b>Percent: </b>
                {getMonthPercent(sto, m)}%
              </td>

              {getDays(sto, m - 1).map((d) => (
                <td key={d}>
                  <select
                    onChange={(event) => handleOptionChange(event, d, m - 1)}
                    className="data-select"
                    value={getDefaultValues(m - 1, d)}
                    disabled={disabledDataSelect(m - 1, d)}
                  >
                    {options.map((o) => (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    ))}
                  </select>
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      ))}
    </div>
  );
};

export default PlanPrint;
