import { gql } from '@apollo/client';

export const SIGNATURE_SAVES_ETTINGS = gql`
  mutation Settings($data: CreateSignatureInput!) {
    saveSignature(data: $data) {
      id
      signature
    }
  }
`;
