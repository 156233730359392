const common = {};

const env = {
  development: {
    ROUTER_BASE_NAME: '/',
    API_BASE_URL: 'http://localhost:3000/api/reshab',
    API_GQL_PATH: '/graphql',
    AUTH0_DOMAIN: 'reshab-dev.us.auth0.com',
    AUTH0_CLIENTID: 'MvSqj7ilG7Imf0SLDjwhs5gtHIFbN8Vc',
    AUTH0_AUDIENCE: 'http://localhost:3000',
  },
  test: {
    ROUTER_BASE_NAME: '/',
    API_BASE_URL: 'http://localhost:3000/api/reshab',
    API_GQL_PATH: '/graphql',
    AUTH0_DOMAIN: 'reshab-dev.us.auth0.com',
    AUTH0_CLIENTID: 'MvSqj7ilG7Imf0SLDjwhs5gtHIFbN8Vc',
    AUTH0_AUDIENCE: 'http://localhost:3000',
  },
  production: {
    ROUTER_BASE_NAME: '/',
    API_BASE_URL: 'https://abcofmiami.com/api/reshab',
    API_GQL_PATH: '/graphql',
    AUTH0_DOMAIN: 'reshab.us.auth0.com',
    AUTH0_CLIENTID: 'zNWL35ZdAYZwkjxdTCCHyaNk8AcEMcUn',
    AUTH0_AUDIENCE: 'https://abcofmiami.com',
  },
};

export default {
  ...common,
  ...env[process.env.REACT_APP_STAGE],
};
