import { gql } from '@apollo/client';

export const STO_CREATE = gql`
  mutation StoCreate($goalId: ID!, $data: CreateStoInput!) {
    stoCreate(goalId: $goalId, data: $data) {
      startDate
      endDate
      promptLevel
      criteria
      frequency
      step
    }
  }
`;

export const STO_UPDATE = gql`
  mutation StoUpdate($id: ID!, $data: CreateStoInput!) {
    stoUpdate(id: $id, data: $data) {
      id
      startDate
      endDate
      promptLevel
      criteria
      frequency
      step
    }
  }
`;

export const STO_DELETE = gql`
  mutation StoDelete($id: ID!) {
    stoDelete(id: $id) {
      id
    }
  }
`;
