import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import UsersList from './UsersList';

import '../style/home.scss';
import '../style/buttons.scss';

const Users: React.FC = () => {
  const history = useHistory();
  const { adminId } = useParams();

  return (
    <div className="user-content">
      <div className="group-header">
        <span className="header">User List</span>

        <button
          className="add-button"
          type="button"
          onClick={() => history.push(`/users/new/${adminId}`)}
        >
          Add User
        </button>
      </div>

      <UsersList />
    </div>
  );
};

export default Users;
