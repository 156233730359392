import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CLIENT, HOME, PLAN, SIGNATURE_SETTING } from '../../../graphql/queries';
import {
  ClientType,
  ClientVariablesType,
  HomeType,
  HomeVariablesType,
  PlanType,
  PlanVariablesType,
  SignatureType,
  SignatureVariableType,
} from '../../../graphql/types';
import Loading from '../../Loading';
import '../../style/page.scss';

const PlanPrint: React.FC = () => {
  const { id, planId, clientId } = useParams();
  const { user } = useAuth0();
  const { loading, data } = useQuery<PlanType, PlanVariablesType>(PLAN, {
    variables: { id: planId },
  });
  let pages: any[] = [];
  let page = 0;
  const clientQuery = useQuery<ClientType, ClientVariablesType>(CLIENT, {
    variables: { id: clientId },
  });

  const homeQuery = useQuery<HomeType, HomeVariablesType>(HOME, {
    variables: { id },
  });
  const SignatureQuery = useQuery<SignatureType, SignatureVariableType>(SIGNATURE_SETTING);

  const gatherGoalsRender = () => {
    const nGoals = data?.plan.goals.edges.length || 0;
    let insidePages: any[] = [];
    pages = [];

    for (let i = 0; i < nGoals; i += 1) {
      const goal = data?.plan.goals.edges[i];
      if (i === 0) {
        pages.push([goal?.node]);
      } else {
        insidePages.push(goal?.node);

        if (i % 3 === 0) {
          pages.push(insidePages);
          insidePages = [];
        } else if (i === nGoals - 1) {
          pages.push(insidePages);
        }
      }
    }
  };

  const partialGoals = (goalNode: any, index: number) => {
    return (
      <div className="goals-content" key={goalNode.id}>
        <div className="goal-content-wraper">
          <span className="goal-content">{`Goal #${index + 1}: `}</span>
          <span>
            {goalNode.target}. {goalNode.notes}
          </span>
        </div>
        <div className="goal-content-wraper">
          <span className="goal-content">Specific Target Behavior: </span>
          <span>{goalNode.specificTargetBehavior}</span>
        </div>
        <div className="goal-content-wraper margin-both-side">
          <span className="goal-content">Baseline and Prompt level: </span>
          <span>{goalNode.baselinePromptLevel}</span>
        </div>

        {goalNode.stos.edges.map((s: any) => (
          <div className="stos-content">
            <div>
              <span className="sto-content">Sto {s.node.index}:</span>
              <span>{`${clientQuery?.data?.client.name} will learn ${goalNode.verb} using ${s.node.promptLevel} for 3 consecituve months ${s.node.criteria}% of trials.`}</span>
            </div>
          </div>
        ))}
        <div className="goal-content-wraper">
          <span className="goal-content">Training Enviroment: </span>
          <span>{goalNode.trainingEnvironment}</span>
        </div>
      </div>
    );
  };

  const partialPlanGoals = (goalNode: any, index: number) => {
    return (
      <div className="page">
        <div className="header"> RESIDENTIAL HABILITATION</div>
        <div className="header"> INDIVIDUAL PROGRAM PLAN</div>

        <div className="client-info-header">
          <div className="home-client-info">Recipient: {clientQuery?.data?.client.name}</div>
          <div className="home-client-info">
            Legal Guardian: {clientQuery?.data?.client.caregiver}
          </div>
          <div className="home-client-info">
            Support Plan Effective Date:{' '}
            {data?.plan.supportDate ? moment(data?.plan.supportDate).format('M/D/yyyy') : ''}
          </div>
          <div className="home-client-info">
            IP Effective Date:{' '}
            {data?.plan.effectiveDate ? moment(data?.plan.effectiveDate).format('M/D/yyyy') : ''}
          </div>
          <div className="home-client-info">Location: {homeQuery?.data?.home.name}</div>
          <div className="home-client-info">
            Group Home Address: {homeQuery?.data?.home.address}
          </div>
          <div className="home-client-info">
            Group Home Contact: {homeQuery?.data?.home.contactName}
          </div>
          <div className="home-client-info">Phone Number: {homeQuery?.data?.home.phone}</div>
          <div className="home-client-info">Consultant: {user?.nickname}</div>
        </div>

        <div className="content">
          <div className="rational-justification">Rational Justification:</div>
          <div className="plan-content">{data?.plan.rationalJustification}</div>
          <div className="continues-test-wraper">
            <span className="assessment-tool">Assessment Tool:</span>
            <span className="plan-content">{data?.plan.assessmentTools}</span>
          </div>
          <div className="continues-test-wraper">
            <span className="stimulus-preference-assessment">Stimulus Preference Assessment:</span>
            <span className="plan-content">{data?.plan.stimulusPreferenceAssessment}</span>
          </div>

          <div className="goals-header">Personal Goals and Outcomes</div>
          {partialGoals(goalNode, index)}
        </div>
      </div>
    );
  };

  const footer = (lastPage: any) => {
    return (
      <div className="page">
        <div className="content">
          {lastPage.length === 1 &&
            pages.length > 1 &&
            lastPage.map((g: any) => {
              page += 1;
              return partialGoals(g, page - 1);
            })}

          <div className="continues-test-wraper">
            <span className="staff-trainers under-line">Staff Trainers: </span>
            <span className="plan-content">{data?.plan.staffTrainers}</span>
          </div>

          <div className="continues-test-wraper">
            <span className="teaching-strategies under-line">Teaching Strategies: </span>
            <span className="plan-content">{data?.plan.teachingStrategies}</span>
          </div>

          <div className="continues-test-wraper">
            <span className="data-collection-system under-line">Data Collection System: </span>
            <span className="plan-content">{data?.plan.dataCollectionSystem}</span>
          </div>

          <div className="continues-test-wraper ">
            <span className="monitoring-plan under-line">Monitoring Plan: </span>
            <span className="plan-content">{data?.plan.monitoringPlan}</span>
          </div>

          <table className="sign-footer">
            <tbody>
              <tr>
                <td>Prepared By:</td>
              </tr>
              <tr>
                <td colSpan={6} className="consultan-sign">
                  {SignatureQuery?.data?.signature?.signature ? (
                    <img
                      alt="signature"
                      className="sigImage"
                      src={SignatureQuery?.data?.signature?.signature}
                    />
                  ) : null}
                </td>
              </tr>
              <tr>
                <td colSpan={4} className="consultan-name">
                  Douglas Perez, MPA, BCBA
                </td>
              </tr>
              <tr>
                <td className="caregiver" colSpan={5}>
                  cc: Resident/Parent/Guardian, Support Coordinator
                </td>
              </tr>
              <tr className="sign-container">
                <td className="sign">Received by:</td>
                <td className="here" />
                <td className="white-space" />
                <td className="sign-date">Date:</td>
                <td className="here" />
                <td className="white-space" />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  gatherGoalsRender();

  if (
    !data ||
    loading ||
    !clientQuery.data ||
    clientQuery.loading ||
    !homeQuery.data ||
    homeQuery.loading ||
    isEmpty(pages)
  )
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="pages-container">
      <div className="buttons">
        <button
          className="add-button plan-botton-back dispose"
          type="button"
          onClick={() => window.close()}
        >
          Close
        </button>
        <button
          className="add-button plan-botton-back print"
          type="button"
          onClick={() => window.print()}
        >
          Print
        </button>
      </div>
      {pages.map((p, index) => {
        if (index === 0) {
          page += 1;
          return partialPlanGoals(p[index], index);
        }
        if (index !== pages.length - 1 || p.length > 1) {
          return (
            <div className="page">
              <div className="content">
                {p.map((g: any) => {
                  page += 1;
                  return partialGoals(g, page - 1);
                })}
              </div>
            </div>
          );
        }

        return null;
      })}
      {footer(pages[pages.length - 1])}
    </div>
  );
};

export default PlanPrint;
