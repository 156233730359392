import { useMutation, MutationTuple } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { CLIENT_CREATE } from '../../../graphql/mutations';
import { CLIENTS } from '../../../graphql/queries';
import { ClientCreateType, ClientCreateVariablesType, ClientsType } from '../../../graphql/types';

export const useClientCreate = (): MutationTuple<ClientCreateType, ClientCreateVariablesType> => {
  const history = useHistory();
  const { id } = useParams();
  const mutation = useMutation<ClientCreateType, ClientCreateVariablesType>(CLIENT_CREATE, {
    update: (cache, data) => {
      const result = data.data?.clientCreate;
      const query = cache.readQuery<ClientsType>({
        variables: { homeId: id },
        query: CLIENTS,
      });
      if (result && query) {
        const { edges } = query.clients;
        cache.writeQuery<ClientsType>({
          variables: { homeId: id },
          query: CLIENTS,
          data: {
            ...query,
            clients: {
              ...query.clients,
              edges: [...edges, { __typename: 'ClientEdge', node: result }],
            },
          },
        });
      }
      history.goBack();
    },
  });

  return mutation;
};
