import { useMutation, MutationTuple, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { HOME_CREATE } from '../../../graphql/mutations';
import { HOMES, USERS } from '../../../graphql/queries';
import {
  HomeCreateType,
  HomeCreateVariablesType,
  HomesType,
  UsersType,
} from '../../../graphql/types';
import { getUserByEmail } from '../../Users/UserService';

export const useHomeCreate = (
  user: any
): MutationTuple<HomeCreateType, HomeCreateVariablesType> => {
  const users = useQuery<UsersType>(USERS);
  const history = useHistory();
  const mutation = useMutation<HomeCreateType, HomeCreateVariablesType>(HOME_CREATE, {
    update: (cache, data) => {
      const result = data.data?.homeCreate;
      const currentUser = getUserByEmail(users.data?.users, user.name);
      const query = cache.readQuery<HomesType>({
        variables: { userId: currentUser.id },
        query: HOMES,
      });
      if (result && query) {
        const { edges } = query.homes;
        cache.writeQuery<HomesType>({
          variables: { userId: currentUser.id },
          query: HOMES,
          data: {
            ...query,
            homes: {
              ...query.homes,
              edges: [...edges, { __typename: 'HomeEdge', node: result }],
            },
          },
        });
      }
      history.push('/');
    },
  });

  return mutation;
};
