import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { BiCheckboxSquare, BiCheckbox } from 'react-icons/bi';
import swal from 'sweetalert';
import { USER, HOMES } from '../../../graphql/queries';
import { HOME_ASSIGN, HOME_UNASSIGN } from '../../../graphql/mutations';
import Loading from '../../Loading';
import {
  HomesType,
  HomeAssignType,
  HomeAssignVariablesType,
  UserType,
  UserVariablesType,
} from '../../../graphql/types';

import '../../style/forms.scss';
import '../../style/buttons.scss';

const AssignHomes: React.FC = () => {
  const history = useHistory();
  const { id, adminId } = useParams();
  const { loading, data } = useQuery<UserType, UserVariablesType>(USER, {
    variables: { id },
  });

  const homeQuery = useQuery<HomesType>(HOMES, {
    variables: { userId: adminId },
  });
  let homeId = '-1';

  const assigned = (home: any) => {
    const userAssigned = home.userHomes.edges.filter((u: any) => {
      return parseInt(u.node.userId, 10) === parseInt(id, 10);
    });

    return userAssigned.length > 0;
  };

  const [homeAssign] = useMutation<HomeAssignType, HomeAssignVariablesType>(HOME_ASSIGN, {
    variables: { id: homeId, userId: id },
    onCompleted: () => history.push(`/users/${id}/assign-home/${adminId}`),
  });

  const [homeUnassign] = useMutation<HomeAssignType, HomeAssignVariablesType>(HOME_UNASSIGN, {
    variables: { id: homeId, userId: id },
    onCompleted: () => history.push(`/users/${id}/assign-home/${adminId}`),
  });

  const assignHome = (home: any) => {
    swal({
      title: 'ASSIGN HOME',
      text: `This action will give access to see all the home(${home.name}) info to this user.`,
      icon: 'warning',
      buttons: ['Cancel', 'Assign Home'],
    }).then((willAssign) => {
      if (willAssign) {
        homeId = home.id;
        homeAssign({ variables: { id: home.id, userId: id } });
      }
    });
  };

  const unassignHome = (home: any) => {
    swal({
      title: 'UNASSIGN HOME',
      text: `This action will remove access to see all the home(${home.name}) info to this user.`,
      icon: 'error',
      buttons: ['Cancel', 'Unassign Home'],
      dangerMode: true,
    }).then((willUnassign) => {
      if (willUnassign) {
        homeId = home.id;
        homeUnassign({ variables: { id: home.id, userId: id } });
      }
    });
  };

  if (!data || loading || !homeQuery.data || homeQuery.loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="home-view-content">
      <div className="group-header">
        <div className="user-name">
          <b>User:</b> {data?.user.name}
          <br />
          <b>Email:</b> {data?.user.email}
          <br />
        </div>
        <button
          className="add-button right"
          type="button"
          onClick={() => history.push(`/users/${adminId}`)}
        >
          Back
        </button>
      </div>
      <div className="assign-list-container">
        <div className="assign-header">Group Home List</div>
        <div className="assign-home-list">
          {homeQuery.data.homes.edges.map(({ node }) => (
            <div className="home-list" key={node.id}>
              {node.name} <b>{assigned(node) ? '(Assigned)' : ''}</b>
              {assigned(node) ? (
                <span className="action-button action-unassigned">
                  <BiCheckbox onClick={() => unassignHome(node)} />
                </span>
              ) : (
                <span className="action-button action-assigned">
                  <BiCheckboxSquare onClick={() => assignHome(node)} />
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AssignHomes;
