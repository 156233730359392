import React from 'react';
import { useHistory, Route } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { BiPencil, BiShow, BiTrash } from 'react-icons/bi';

import swal from 'sweetalert';
import { HOMES } from '../../graphql/queries';
import { HOME_DELETE } from '../../graphql/mutations';
import { HomesType, HomeDeleteType, HomeDeleteVariablesType } from '../../graphql/types';
import EditHome from './EditHome';
import Loading from '../Loading';

import '../style/card.scss';

type Props = {
  currentUser: any;
};

const HomesList: React.FC<Props> = (props) => {
  const history = useHistory();
  const { currentUser } = props;
  const { loading, data } = useQuery<HomesType>(HOMES, {
    variables: { userId: currentUser?.id },
  });
  let homeId = '-1';

  const [homeDeleted] = useMutation<HomeDeleteType, HomeDeleteVariablesType>(HOME_DELETE, {
    variables: { id: homeId },
    update: (cache, home) => {
      const result = home.data?.homeDelete;
      const query = cache.readQuery<HomesType>({
        variables: { userId: currentUser.id },
        query: HOMES,
      });
      if (result && query) {
        const filtered = query?.homes.edges.filter(({ node }) => node.id !== result.id) || [];
        cache.writeQuery<HomesType>({
          variables: { userId: currentUser.id },
          query: HOMES,
          data: {
            ...query,
            homes: {
              ...query.homes,
              edges: filtered,
            },
          },
        });

        history.push('/');
      }
    },
  });

  const removeHome = (node: any) => {
    swal({
      title: 'DELETE HOME',
      text: `This action will remove the home(${node.name}), you will not be able to recover this home information.`,
      icon: 'error',
      buttons: ['Cancel', 'Delete Home'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        homeId = node.id;
        homeDeleted({ variables: { id: node.id } });
      }
    });
  };

  const renderActionButtons = (node: any) => {
    if (currentUser && currentUser.admin) {
      return (
        <div className="action-container">
          <span className="action-see">
            <BiShow onClick={() => history.push(`/homes/${node.id}/clients/undefined`)} />
          </span>

          <span className="action-remove">
            <BiTrash onClick={() => removeHome(node)} />
          </span>

          <span className="action-edit">
            <BiPencil onClick={() => history.push(`/homes/${node.id}/edit`)} />
            <Route path="/homes/:id/edit" component={EditHome} />
          </span>
        </div>
      );
    }

    return (
      <div className="action-container">
        <span className="action-see">
          <BiShow onClick={() => history.push(`/homes/${node.id}/clients/undefined`)} />
        </span>
      </div>
    );
  };

  if (loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="cards-content">
      {data &&
        data.homes.edges.map(({ node }) => (
          <div className="card" key={node.id}>
            <span className="name">{node.name}</span>
            <span className="contact">{node.contactName}</span>
            <span className="address">{node.address}</span>
            <span className="phone">{node.phone}</span>

            {renderActionButtons(node)}
          </div>
        ))}
    </div>
  );
};

export default HomesList;
