import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { useHomeCreate } from './lib';

import '../../style/buttons.scss';
import '../../style/home.scss';

interface Inputs {
  name: string;
  address: string;
  phone: string;
  email: string;
  contactName: string;
}

const NewHome: React.FC = () => {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm<Inputs>();
  const { user } = useAuth0();

  const [homeCreate] = useHomeCreate(user);
  const onSubmit = (values: Inputs) => {
    homeCreate({
      variables: { data: values },
    });
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit(onSubmit)} className="new-edit-form">
        <span className="form-header">New Home</span>
        <div className="fields">
          <div className="form-label">Name</div>
          <input type="text" name="name" ref={register({ required: true })} />
          {errors.name && <div className="error-form">Name is required*</div>}
        </div>

        <div className="fields">
          <div className="form-label">Address</div>
          <input type="text" name="address" ref={register({ required: true })} />
          {errors.address && <div className="error-form">Address is required*</div>}
        </div>

        <div className="fields">
          <div className="form-label">Phone</div>
          <input type="text" name="phone" ref={register({ required: true })} />
          {errors.phone && <div className="error-form">Phone is required*</div>}
        </div>

        <div className="fields">
          <div className="form-label">Email</div>
          <input type="text" name="email" ref={register({ required: true })} />
          {errors.email && <div className="error-form">Email is required*</div>}
        </div>

        <div className="fields">
          <div className="form-label">Contact Name</div>
          <input type="text" name="contactName" ref={register({ required: true })} />
          {errors.contactName && <div className="error-form">Contact name is required*</div>}
        </div>

        <div className="form-buttons">
          <button className="home-save-form-button" type="submit">
            Save
          </button>
          <button className="home-back-form-button" type="button" onClick={() => history.push('/')}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewHome;
