import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { Router, Switch } from 'react-router-dom';
import { PrivateRoute } from '../auth';
import env from '../environment';
import AuthenticatedApolloProvider from '../graphql/AuthenticatedApolloProvider';
import { history } from '../util';
import Layout from './Layout';

import Clients from './Clients';
import EditClient from './Clients/EditClient';
import NewClient from './Clients/NewClient';

import Homes from './Homes';
import AssignHomes from './Homes/AssignHome';
import EditHome from './Homes/EditHome';
import Home from './Homes/Home';
import NewHome from './Homes/NewHome';

import Users from './Users';
import EditUser from './Users/EditUser';
import NewUser from './Users/NewUser';

import EditPlan from './Plans/EditPlan';
import PlanEmptySheet from './Plans/EmptySheet';
import NewPlan from './Plans/NewPlan';
import Plan from './Plans/Plan';
import PlanPrint from './Plans/Print';
import PlanReport from './Plans/Report';

import Goals from './Goals';
import Stos from './Stos';

import Settings from './Settings';
import Signature from './Settings/Signature';

const PrivateRoutes: React.FC = () => (
  <Layout>
    <Switch>
      <PrivateRoute component={NewClient} path="/homes/:id/clients/new" />
      <PrivateRoute component={EditClient} path="/clients/:id/edit" />
      <PrivateRoute component={NewUser} path="/users/new/:adminId" />
      <PrivateRoute component={EditUser} path="/users/:id/edit/:adminId" />
      <PrivateRoute component={NewPlan} path="/clients/:id/plans/new" />
      <PrivateRoute component={EditPlan} path="/plans/:id/edit" />
      <PrivateRoute component={NewHome} path="/homes/new" />
      <PrivateRoute component={EditHome} path="/homes/:id/edit" />
      <PrivateRoute component={Home} path="/homes/:id/clients/:clientId" />
      <PrivateRoute component={AssignHomes} path="/users/:id/assign-home/:adminId" />
      <PrivateRoute component={Plan} path="/homes/:id/client/:clientId/plan/:planId/show" />
      <PrivateRoute component={PlanPrint} path="/homes/:id/client/:clientId/plan/:planId/print" />
      <PrivateRoute
        component={PlanEmptySheet}
        path="/plans/:id/goal/:goalId/sto/:stoId/client/:clientId/empty-sheet"
      />
      <PrivateRoute
        component={PlanReport}
        path="/homes/:id/client/:clientId/plan/:planId/report/:selectedQuarter"
      />
      <PrivateRoute component={Users} path="/users/:adminId" />
      <PrivateRoute component={Clients} path="/homes/:id/clients" />
      <PrivateRoute component={Goals} path="/homes/:id/plan/:planId/client/:clientId/goals" />
      <PrivateRoute
        component={Stos}
        path="/homes/:id/plan/:planId/client/:clientId/goal/:goalId/stos"
      />
      <PrivateRoute component={Settings} path="/settings" />
      <PrivateRoute component={Signature} path="/signature" />

      <PrivateRoute component={Homes} path="/" />
    </Switch>
  </Layout>
);

const App: React.FC = () => (
  <Auth0Provider
    domain={env.AUTH0_DOMAIN}
    clientId={env.AUTH0_CLIENTID}
    audience={env.AUTH0_AUDIENCE}
    redirectUri={window.location.origin}
    onRedirectCallback={(appState) => {
      history.replace((appState && appState.returnTo) || window.location.pathname);
    }}
  >
    <AuthenticatedApolloProvider>
      <Router history={history}>
        <PrivateRoutes />
      </Router>
    </AuthenticatedApolloProvider>
  </Auth0Provider>
);

export default App;
