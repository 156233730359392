import { useMutation, MutationTuple } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { STO_CREATE } from '../../../graphql/mutations';
import { STOS, PLAN } from '../../../graphql/queries';
import { StoCreateType, StoCreateVariablesType, StosType, PlanType } from '../../../graphql/types';

export const useStoCreate = (): MutationTuple<StoCreateType, StoCreateVariablesType> => {
  const history = useHistory();
  const { id, planId, clientId, goalId } = useParams();
  const mutation = useMutation<StoCreateType, StoCreateVariablesType>(STO_CREATE, {
    update: (cache, data) => {
      const result = data.data?.stoCreate;
      const query = cache.readQuery<StosType>({
        variables: { goalId },
        query: STOS,
      });
      const planQuery = cache.readQuery<PlanType>({
        variables: { id: planId },
        query: PLAN,
      });

      if (result && query && planQuery) {
        const { edges } = query.stos;
        cache.writeQuery<StosType>({
          variables: { goalId },
          query: STOS,
          data: {
            ...query,
            stos: {
              ...query.stos,
              edges: [...edges, { __typename: 'StoEdge', node: result }],
            },
          },
        });

        const newEdges = JSON.parse(JSON.stringify(planQuery.plan.goals.edges));
        const goalsSelected = newEdges.filter((g: any) => {
          return g.node.id === goalId;
        });

        goalsSelected[0].node.stos.edges = [
          ...goalsSelected[0].node.stos.edges,
          { __typename: 'StoEdge', node: result },
        ];

        cache.writeQuery<PlanType>({
          variables: { id: planId },
          query: PLAN,
          data: {
            ...planQuery,
            plan: {
              ...planQuery.plan,
              goals: {
                ...planQuery.plan.goals,
                edges: [...newEdges],
              },
            },
          },
        });

        history.push(`/homes/${id}/plan/${planId}/client/${clientId}/goal/${goalId}/stos`);
      }
    },
  });

  return mutation;
};
