import { gql } from '@apollo/client';

export const CLIENTS = gql`
  query Clients($homeId: ID!) {
    clients(homeId: $homeId) {
      edges {
        node {
          id
          name
          caregiver
        }
      }
    }
  }
`;

export const CLIENT = gql`
  query Client($id: ID!) {
    client(id: $id) {
      id
      name
      caregiver
    }
  }
`;
