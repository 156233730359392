import { gql } from '@apollo/client';

export const CLIENT_CREATE = gql`
  mutation ClientCreate($homeId: ID!, $data: CreateClientInput!) {
    clientCreate(homeId: $homeId, data: $data) {
      name
      caregiver
    }
  }
`;

export const CLIENT_UPDATE = gql`
  mutation ClientUpdate($id: ID!, $data: CreateClientInput!) {
    clientUpdate(id: $id, data: $data) {
      id
      name
      caregiver
    }
  }
`;

export const CLIENT_DELETE = gql`
  mutation ClientDelete($id: ID!) {
    clientDelete(id: $id) {
      id
    }
  }
`;
