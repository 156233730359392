import React from 'react';
import { useHistory, Route, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { BiPencil, BiHomeHeart, BiTrash } from 'react-icons/bi';

import swal from 'sweetalert';
import { USERS } from '../../graphql/queries';
import { USER_DELETE } from '../../graphql/mutations';
import { UsersType, UserDeleteType, UserDeleteVariablesType } from '../../graphql/types';
import EditUser from './EditUser';
import Loading from '../Loading';

import '../style/client.scss';

const UsersList: React.FC = () => {
  const { adminId } = useParams();
  const history = useHistory();
  const { loading, data } = useQuery<UsersType>(USERS);
  let userId = '-1';

  const [userDeleted] = useMutation<UserDeleteType, UserDeleteVariablesType>(USER_DELETE, {
    variables: { id: userId },
    update: (cache, user) => {
      const result = user.data?.userDelete;
      const query = cache.readQuery<UsersType>({ query: USERS });
      if (result && query) {
        const filtered = query?.users.edges.filter(({ node }) => node.id !== result.id) || [];
        cache.writeQuery<UsersType>({
          query: USERS,
          data: {
            ...query,
            users: {
              ...query.users,
              edges: filtered,
            },
          },
        });

        history.push(`/users/${adminId}`);
      }
    },
  });

  const removeUser = (node: any) => {
    swal({
      title: 'DELETE User',
      text: `User(${node.name}) will be removed, you will not be able to recover this user information.`,
      icon: 'error',
      buttons: ['Cancel', 'Delete User'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        userId = node.id;
        userDeleted({ variables: { id: node.id } });
      }
    });
  };

  if (loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="user-list-warper">
      <div className="users-list-container">
        {data &&
          data.users.edges.map(({ node }) => (
            <div className="user" key={node.id}>
              <span className="name">
                <b>Name:</b> {node.name}
                <br />
                <b>Email:</b> {node.email}
                <b>{node.admin ? ' (Admin)' : ' '}</b>
              </span>

              {!node.admin ? (
                <span className="action-home">
                  <BiHomeHeart
                    onClick={() => history.push(`/users/${node.id}/assign-home/${adminId}`)}
                  />
                </span>
              ) : null}

              <span className="action-edit">
                <BiPencil onClick={() => history.push(`/users/${node.id}/edit/${adminId}`)} />
                <Route path="/users/:id/edit" component={EditUser} />
              </span>

              <span className="action-remove">
                <BiTrash onClick={() => removeUser(node)} />
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default UsersList;
