import React from 'react';

import '../style/loading.scss';
import { RiLoaderLine } from 'react-icons/ri';

const Loading: React.FC = () => {
  return (
    <div className="loading-content">
      <RiLoaderLine />
    </div>
  );
};

export default Loading;
