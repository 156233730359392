import React from 'react';
import { useQuery } from '@apollo/client';
import { Route, useParams, useHistory } from 'react-router-dom';
import { GoalType, PlanType, ClientType } from '../../graphql/types';
import { GOAL, PLAN, CLIENT } from '../../graphql/queries';
import Loading from '../Loading';
import NewSto from './NewSto';
import EditSto from './EditSto';
import StoList from './StoList';

import '../style/home.scss';

const Stos: React.FC = () => {
  const { id, planId, clientId, goalId } = useParams();
  const history = useHistory();
  const planQuery = useQuery<PlanType>(PLAN, {
    variables: { id: planId },
  });

  const clientQuery = useQuery<ClientType>(CLIENT, {
    variables: { id: clientId },
  });

  const goalQuery = useQuery<GoalType>(GOAL, {
    variables: { id: goalId },
  });

  if (planQuery.loading || clientQuery.loading || goalQuery.loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  if (!clientQuery.data || !planQuery.data || !goalQuery.data) return null;

  return (
    <div className="home-view-content">
      <div className="group-header">
        <span className="header">
          Client: {clientQuery.data.client.name} - Plan: {planQuery.data.plan.index} - Goal:{' '}
          {goalQuery.data.goal.target}
        </span>
        <button
          className="add-button left goal"
          type="button"
          onClick={() => history.push(`/homes/${id}/plan/${planId}/client/${clientId}/goals`)}
        >
          Back
        </button>
        <button
          className="add-button right"
          type="button"
          onClick={() =>
            history.push(`/homes/${id}/plan/${planId}/client/${clientId}/goal/${goalId}/stos/new`)
          }
        >
          Add Sto
        </button>
      </div>

      <div className="client-list-container">
        <StoList />
      </div>

      <div className="plan-list-container">
        <Route
          component={NewSto}
          path="/homes/:id/plan/:planId/client/:clientId/goal/:goalId/stos/new"
        />
        <Route
          component={EditSto}
          path="/homes/:id/plan/:planId/client/:clientId/goal/:goalId/stos/:stoId/edit"
        />
      </div>
    </div>
  );
};

export default Stos;
