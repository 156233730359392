import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { HOME } from '../../../graphql/queries';
import { HOME_UPDATE } from '../../../graphql/mutations';
import Loading from '../../Loading';
import {
  HomeUpdateType,
  HomeUpdateVariablesType,
  HomeType,
  HomeVariablesType,
} from '../../../graphql/types';

import '../../style/forms.scss';
import '../../style/buttons.scss';

interface Inputs {
  name: string;
  address: string;
  phone: string;
  email: string;
  contactName: string;
}

const EditHome: React.FC = () => {
  const history = useHistory();
  const { id } = useParams();
  const { register, handleSubmit, errors, reset } = useForm<Inputs>();
  const { loading, data } = useQuery<HomeType, HomeVariablesType>(HOME, {
    variables: { id },
    onCompleted: ({ home: { name, address, phone, email, contactName } }) =>
      reset({ name, address, phone, email, contactName }),
  });
  const [homeUpdate] = useMutation<HomeUpdateType, HomeUpdateVariablesType>(HOME_UPDATE, {
    onCompleted: () => history.push('/'),
  });

  const onSubmit = (values: Inputs) => {
    homeUpdate({ variables: { id, data: values } });
  };

  if (loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="form-container">
      {data && (
        <form onSubmit={handleSubmit(onSubmit)} className="new-edit-form">
          <span className="form-header">Edit Home</span>
          <div className="fields">
            <div className="form-label">Name</div>
            <input type="text" name="name" ref={register({ required: true })} />
            {errors.name && <div className="error-form">Name is required*</div>}
          </div>
          <div className="fields">
            <div className="form-label">Address</div>
            <input type="text" name="address" ref={register({ required: true })} />
            {errors.address && <div className="error-form">Address is required*</div>}
          </div>
          <div className="fields">
            <div className="form-label">Phone</div>
            <input type="text" name="phone" ref={register({ required: true })} />
            {errors.phone && <div>Phone is required*</div>}
          </div>
          <div className="fields">
            <div className="form-label">Email</div>
            <input type="text" name="email" ref={register({ required: true })} />
            {errors.email && <div className="error-form">Email is required*</div>}
          </div>
          <div className="fields">
            <div className="form-label">Contact Name</div>
            <input type="text" name="contactName" ref={register({ required: true })} />
            {errors.contactName && <div className="error-form">Contact name is required*</div>}
          </div>
          <div className="form-buttons">
            <button className="home-save-form-button" type="submit">
              Save
            </button>
            <button
              className="home-back-form-button"
              type="button"
              onClick={() => history.push(`/`)}
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditHome;
