import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { STO_UPDATE } from '../../../graphql/mutations';
import { STO } from '../../../graphql/queries';
import {
  StoType,
  StoUpdateType,
  StoUpdateVariablesType,
  StoVariablesType,
} from '../../../graphql/types';

import Loading from '../../Loading';
import '../../style/forms.scss';

interface Inputs {
  index: number;
  startDate: Date;
  endDate: Date;
  promptLevel: string;
  criteria: string;
  frequency: string;
  step: string;
}

const EditSto: React.FC = () => {
  const history = useHistory();
  const { id, planId, clientId, goalId, stoId } = useParams();
  const { register, handleSubmit, errors, reset } = useForm<Inputs>();
  const { loading, data } = useQuery<StoType, StoVariablesType>(STO, {
    variables: { id: stoId },
    onCompleted: ({ sto: { index, startDate, endDate, promptLevel, criteria, frequency, step } }) =>
      reset({
        index,
        startDate,
        endDate,
        promptLevel,
        criteria,
        frequency,
        step,
      }),
  });
  const [stoUpdate] = useMutation<StoUpdateType, StoUpdateVariablesType>(STO_UPDATE, {
    onCompleted: () =>
      history.push(`/homes/${id}/plan/${planId}/client/${clientId}/goal/${goalId}/stos`),
  });

  const onSubmit = (values: Inputs) => {
    stoUpdate({ variables: { id: stoId, data: values } });
  };

  if (loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="form-container">
      {data && (
        <form onSubmit={handleSubmit(onSubmit)} className="new-edit-form">
          <span className="form-header">Edit Sto</span>
          <div className="fields">
            <div className="form-label">Start Date</div>
            <input
              type="date"
              className="sto"
              name="startDate"
              ref={register({ required: true })}
            />
            {errors.startDate && <div className="error-form">Start Date is required*</div>}
          </div>

          <div className="fields">
            <div className="form-label">End Date</div>
            <input type="date" className="sto" name="endDate" ref={register({ required: true })} />
            {errors.endDate && <div className="error-form">End Date is required*</div>}
          </div>

          <div className="fields">
            <div className="form-label">Prompt Level</div>
            <input
              type="text"
              className="sto"
              name="promptLevel"
              ref={register({ required: true })}
            />
            {errors.promptLevel && <div className="error-form">Prompt Level is required*</div>}
          </div>

          <div className="fields">
            <div className="form-label">Criteria</div>
            <input
              type="number"
              className="sto"
              name="criteria"
              ref={register({ required: true })}
            />
            {errors.criteria && <div className="error-form">Criteria is required*</div>}
          </div>

          <div className="fields">
            <div className="form-label">Frequency</div>
            <input
              type="text"
              className="sto"
              name="frequency"
              ref={register({ required: true })}
            />
            {errors.frequency && <div className="error-form">Frequency is required*</div>}
          </div>

          <div className="fields">
            <div className="form-label">Steps</div>
            <input type="text" className="sto" name="step" ref={register({ required: true })} />
            {errors.step && <div className="error-form">Steps is required*</div>}
          </div>

          <div className="form-buttons">
            <button className="home-save-form-button" type="submit">
              Save
            </button>
            <button
              className="home-back-form-button"
              type="button"
              onClick={() =>
                history.push(`/homes/${id}/plan/${planId}/client/${clientId}/goal/${goalId}/stos`)
              }
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditSto;
