import { useMutation, MutationTuple } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { STO_COUNTER_CREATE } from '../../../graphql/mutations';
import { PLAN } from '../../../graphql/queries';
import {
  PlanType,
  StoCounterCreateType,
  StoCounterCreateVariablesType,
} from '../../../graphql/types';

export const useStoCounterCreate = (
  stoId: string,
  goalId: string
): MutationTuple<StoCounterCreateType, StoCounterCreateVariablesType> => {
  const history = useHistory();
  const { id, clientId, planId } = useParams();
  const mutation = useMutation<StoCounterCreateType, StoCounterCreateVariablesType>(
    STO_COUNTER_CREATE,
    {
      update: (cache, data) => {
        const result = data.data?.stoCounterCreate;

        const query = cache.readQuery<PlanType>({
          variables: { id: planId },
          query: PLAN,
        });

        if (result && query) {
          const { edges } = query.plan.goals;

          const newEdges = JSON.parse(JSON.stringify(edges));

          const goalsSelected = newEdges.filter((g: any) => {
            return g.node.id === goalId;
          });

          const selectedSto = goalsSelected[0].node.stos.edges.filter((s: any) => {
            return s.node.id === stoId;
          });

          selectedSto[0].node.stoCounters.edges = [
            ...selectedSto[0].node.stoCounters.edges,
            {
              __typename: 'StoCounterEdge',
              node: result,
            },
          ];

          cache.writeQuery<PlanType>({
            variables: { id: planId },
            query: PLAN,
            data: {
              ...query,
              plan: {
                ...query.plan,
                goals: {
                  ...query.plan.goals,
                  edges: [...newEdges],
                },
              },
            },
          });
          history.push('/');
          history.push(`/homes/${id}/client/${clientId}/plan/${planId}/show`);
        }
      },
    }
  );

  return mutation;
};
