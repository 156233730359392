export const getUserByEmail = (users: any, email: string) => {
  const user = users.edges.filter((u: any) => {
    return u.node.email === email;
  });

  if (user[0]) {
    return user[0].node;
  }

  return null;
};
