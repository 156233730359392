import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { BiPencil, BiShow, BiTrash, BiBullseye, BiPrinter, BiBarChart } from 'react-icons/bi';
import moment from 'moment';
import swal from 'sweetalert';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ClientType,
  PlansType,
  PlanDeleteType,
  PlanDeleteVariablesType,
  UsersType,
} from '../../graphql/types';
import { CLIENT, PLANS, USERS } from '../../graphql/queries';
import { PLAN_DELETE } from '../../graphql/mutations';
import Loading from '../Loading';
import { getUserByEmail } from '../Users/UserService';

import '../style/plan.scss';

const Plans: React.FC = () => {
  const { id, clientId } = useParams();
  const history = useHistory();
  const clientQuery = useQuery<ClientType>(CLIENT, {
    variables: { id: clientId },
  });
  const userQuery = useQuery<UsersType>(USERS);

  const [planDeleted] = useMutation<PlanDeleteType, PlanDeleteVariablesType>(PLAN_DELETE, {
    variables: { id },
    update: (cache, data) => {
      const result = data.data?.planDelete;
      const query = cache.readQuery<PlansType>({
        variables: { clientId },
        query: PLANS,
      });
      if (result && query) {
        const filtered = query?.plans.edges.filter(({ node }) => node.id !== result.id) || [];
        cache.writeQuery<PlansType>({
          variables: { clientId },
          query: PLANS,
          data: {
            ...query,
            plans: {
              ...query?.plans,
              edges: filtered,
            },
          },
        });
      }
    },
  });

  const client = clientQuery.data?.client;
  const clientLoading = clientQuery.loading;
  const [showOptions, setShowOptions] = useState({ id: '' });
  const { user } = useAuth0();
  let currentUser: any = {};

  const { loading, data } = useQuery<PlansType>(PLANS, {
    variables: { clientId },
  });

  const listClick = (planId: string, quarter: number) => {
    setShowOptions({ id: '' });
    window
      .open(`/homes/${id}/client/${clientId}/plan/${planId}/report/${quarter}`, '_blank')
      ?.focus();
  };

  const removePlan = (node: any) => {
    swal({
      title: 'DELETE PLAN',
      text: `This action will remove the plan(${node.index}), you will not be able to recover this plan information.`,
      icon: 'error',
      buttons: ['Cancel', 'Delete Plan'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        planDeleted({ variables: { id: node.id } });
      }
    });
  };

  const getCurrentUser = () => {
    currentUser = getUserByEmail(userQuery.data?.users, user.name);
  };

  const renderActionButtons = (node: any) => {
    if (currentUser && currentUser.admin) {
      return (
        <div className="action-container">
          <span className="action-goal">
            <BiBullseye
              onClick={() => history.push(`/homes/${id}/plan/${node.id}/client/${clientId}/goals`)}
            />
          </span>

          <span className="action-see">
            <BiShow
              onClick={() => history.push(`/homes/${id}/client/${clientId}/plan/${node.id}/show`)}
            />
          </span>

          <span className="action-remove">
            <BiTrash onClick={() => removePlan(node)} />
          </span>

          <span className="action-edit">
            <BiPencil onClick={() => history.push(`/plans/${node.id}/edit`)} />
          </span>

          <span className="action-print">
            <BiPrinter
              onClick={() => {
                window
                  .open(`/homes/${id}/client/${clientId}/plan/${node.id}/print`, '_blank')
                  ?.focus();
              }}
            />
          </span>

          <span className="action-report">
            <BiBarChart
              onClick={() => {
                if (showOptions.id === '' || showOptions.id !== node.id) {
                  setShowOptions({ id: node.id });
                } else {
                  setShowOptions({ id: '' });
                }
              }}
            />
          </span>
          {showOptions.id === node.id && (
            <div className="dropdown" key={node.id}>
              <button
                type="button"
                className="report-dropdown-button"
                onClick={() => listClick(node.id, 1)}
              >
                Quarter 1
              </button>
              <button
                type="button"
                className="report-dropdown-button"
                onClick={() => listClick(node.id, 2)}
              >
                Quarter 2
              </button>
              <button
                type="button"
                className="report-dropdown-button"
                onClick={() => listClick(node.id, 3)}
              >
                Quarter 3
              </button>
              <button
                type="button"
                className="report-dropdown-button"
                onClick={() => listClick(node.id, 4)}
              >
                Quarter 4
              </button>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="action-container">
        <span className="action-see">
          <BiShow
            onClick={() => history.push(`/homes/${id}/client/${clientId}/plan/${node.id}/show`)}
          />
        </span>

        <span className="action-print">
          <BiPrinter
            onClick={() => {
              window
                .open(`/homes/${id}/client/${clientId}/plan/${node.id}/print`, '_blank')
                ?.focus();
            }}
          />
        </span>

        <span className="action-report">
          <BiBarChart
            onClick={() => {
              if (showOptions.id === '' || showOptions.id !== node.id) {
                setShowOptions({ id: node.id });
              } else {
                setShowOptions({ id: '' });
              }
            }}
          />
        </span>
        {showOptions.id === node.id && (
          <div className="dropdown" key={node.id}>
            <button
              type="button"
              className="report-dropdown-button"
              onClick={() => listClick(node.id, 1)}
            >
              Quarter 1
            </button>
            <button
              type="button"
              className="report-dropdown-button"
              onClick={() => listClick(node.id, 2)}
            >
              Quarter 2
            </button>
            <button
              type="button"
              className="report-dropdown-button"
              onClick={() => listClick(node.id, 3)}
            >
              Quarter 3
            </button>
            <button
              type="button"
              className="report-dropdown-button"
              onClick={() => listClick(node.id, 4)}
            >
              Quarter 4
            </button>
          </div>
        )}
      </div>
    );
  };

  getCurrentUser();

  if (clientId === 'undefined') {
    return (
      <div className="group-plan-content">
        <div className="not-info">
          <span className="empty-text">Select a client.</span>
        </div>
      </div>
    );
  }

  if (clientLoading || loading || userQuery.loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="group-plan-content">
      <div className="group-header">
        <span className="header">{`${client?.name}'s Plans`}</span>
      </div>
      <div className="cards-content">
        {data &&
          data.plans.edges.map(({ node }) => (
            <div className="card plan" key={node.id}>
              <span className="name">Plan {node.index}</span>
              <span className="contact">
                Start Date: {moment(node.startDate).format('M/D/yyyy')}
              </span>
              <span className="contact">End Date: {moment(node.endDate).format('M/D/yyyy')}</span>
              <span className="address">Goal Amount: {node.goals.edges.length}</span>

              {renderActionButtons(node)}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Plans;
