import { gql } from '@apollo/client';

export const HOME_CREATE = gql`
  mutation HomeCreate($data: CreateHomeInput!) {
    homeCreate(data: $data) {
      id
      name
      address
      phone
      email
      contactName
    }
  }
`;

export const HOME_UPDATE = gql`
  mutation HomeUpdate($id: ID!, $data: CreateHomeInput!) {
    homeUpdate(id: $id, data: $data) {
      id
      name
      address
      phone
      email
      contactName
    }
  }
`;

export const HOME_DELETE = gql`
  mutation HomeDelete($id: ID!) {
    homeDelete(id: $id) {
      id
    }
  }
`;

export const HOME_ASSIGN = gql`
  mutation HomeAssign($id: ID!, $userId: ID!) {
    homeAssign(id: $id, userId: $userId) {
      id
      name
      address
      phone
      email
      contactName
      userHomes {
        edges {
          node {
            id
            userId
            homeId
            deleted
          }
        }
      }
    }
  }
`;

export const HOME_UNASSIGN = gql`
  mutation HomeUnassign($id: ID!, $userId: ID!) {
    homeUnassign(id: $id, userId: $userId) {
      id
      name
      address
      phone
      email
      contactName
      userHomes {
        edges {
          node {
            id
            userId
            homeId
            deleted
          }
        }
      }
    }
  }
`;
