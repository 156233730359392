import { gql } from '@apollo/client';

export const USER_CREATE = gql`
  mutation UserCreate($data: CreateUserInput!) {
    userCreate(data: $data) {
      id
      email
      name
      admin
    }
  }
`;

export const USER_UPDATE = gql`
  mutation UserUpdate($id: ID!, $data: CreateUserInput!) {
    userUpdate(id: $id, data: $data) {
      id
      email
      name
      admin
    }
  }
`;

export const USER_DELETE = gql`
  mutation UserDelete($id: ID!) {
    userDelete(id: $id) {
      id
    }
  }
`;
