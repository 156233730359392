import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { AiOutlineLogout } from 'react-icons/ai';
import { SiAtom } from 'react-icons/si';
import { USERS } from '../graphql/queries';
import { UsersType } from '../graphql/types';
import './style/layout.scss';
import { getUserByEmail } from './Users/UserService';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const Layout: React.FC = ({ children }) => {
  const { user, logout } = useAuth0();
  const history = useHistory();
  const { data } = useQuery<UsersType>(USERS);

  const getPrintHideClass = (nameClass: string) => {
    if (
      history.location.pathname.includes('print') ||
      history.location.pathname.includes('report') ||
      history.location.pathname.includes('empty-sheet')
    ) {
      if (history.location.pathname.includes('empty-sheet')) {
        document.getElementsByTagName('html')[0].classList.add('landscape');
        document.body.classList.add('landscape');
        return `${nameClass} landscape`;
      }
      return nameClass;
    }
    return '';
  };

  const hideShowSettings = () => {
    if (data && user) {
      const currentUser = getUserByEmail(data.users, user.name);
      if (currentUser && currentUser.admin) {
        return (
          <span className="settings-button-container">
            <button type="button" className="settings" onClick={() => history.push(`/settings`)}>
              Settings
            </button>
          </span>
        );
      }
    }

    return null;
  };

  const hideShowUser = () => {
    if (data && user) {
      const currentUser = getUserByEmail(data.users, user.name);
      if (currentUser && currentUser.admin) {
        return (
          <span className="user-name">
            <button
              type="button"
              className="users"
              onClick={() => history.push(`/users/${currentUser.id}`)}
            >
              Users
            </button>
          </span>
        );
      }
    }

    return null;
  };

  return (
    <div className="app">
      <div className={`navbar ${getPrintHideClass('hide')}`}>
        <div className="left-content">
          <span className="logo-content">
            <SiAtom onClick={() => history.push(`/`)} />
          </span>
        </div>

        <div className="right-content">
          {hideShowSettings()}
          {hideShowUser()}
          <span className="user-name">{user?.name}</span>
          <span className="log-out">
            <AiOutlineLogout onClick={() => logout({ returnTo: window.location.origin })} />
          </span>
        </div>
      </div>

      <div className={`app-content ${getPrintHideClass('print')}`}>{children}</div>
    </div>
  );
};

export default Layout;
