import React from 'react';
import SettingsList from './SettingsList';

import '../style/setting.scss';

const Settings: React.FC = () => {
  return (
    <div className="setting-content">
      <div className="group-header">
        <div className="header">Settings</div>
      </div>
      <SettingsList />
    </div>
  );
};

export default Settings;
