import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import React from 'react';
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';
import { CLIENT, PLAN } from '../../../graphql/queries';
import {
  ClientType,
  ClientVariablesType,
  PlanType,
  PlanVariablesType,
} from '../../../graphql/types';
import Loading from '../../Loading';
import '../../style/home.scss';
import '../../style/page.scss';
import { getDays, getMonths } from '../PlanService';

const PlanEmptySheet: React.FC = () => {
  const { id, goalId, stoId, clientId } = useParams();
  const { user } = useAuth0();
  const { loading, data } = useQuery<PlanType, PlanVariablesType>(PLAN, {
    variables: { id },
  });

  const clientQuery = useQuery<ClientType, ClientVariablesType>(CLIENT, {
    variables: { id: clientId },
  });

  if (!data || loading || !clientQuery.data || clientQuery.loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  const goal = data.plan.goals.edges.filter((g) => {
    return g.node.id === goalId;
  })[0];

  const sto = goal.node.stos.edges.filter((s) => {
    return s.node.id === stoId;
  })[0];

  return (
    <div className="pages-container">
      <div className="buttons">
        <button
          className="add-button plan-botton-back dispose"
          type="button"
          onClick={() => window.close()}
        >
          Close
        </button>
        <button
          className="add-button plan-botton-back print"
          type="button"
          onClick={() => window.print()}
        >
          Print
        </button>
      </div>

      <div className="page landscape">
        <div className="container">
          <div className="sheet-container-header">
            <b>Client: {clientQuery.data.client.name}</b>
            <br />
            <b>Plan #: {data.plan.index}</b>
            <br />
            <b>Goal: {goal.node.target}</b>
            <br />
            <b>Sto #: {sto.node.index}</b>
          </div>
          {getMonths(sto.node, true).map((m: any) => (
            <Table bordered key={m}>
              <thead>
                <tr>
                  <th className="remove-borders">Days of the Month</th>
                  {getDays(sto.node, m - 1).map((d) => (
                    <th key={d}>{d}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>STO Month:</b>{' '}
                    {moment()
                      .month(m - 1)
                      .format('MMM')}
                    <br />
                    <b>Prompt Level:</b> {sto.node.promptLevel} <br />
                    <b>Criteria:</b> {sto.node.criteria}% <br />
                    <b>Frecuency:</b> {sto.node.frequency} <br />
                    <b>Steps:</b> 1-8 <br />
                    <b>Percent: </b> N/A
                  </td>

                  {getDays(sto.node, m - 1).map((d) => (
                    <td key={d} />
                  ))}
                </tr>
              </tbody>
            </Table>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlanEmptySheet;
