import React, { useEffect } from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

// TODO: find out what the problem is with withAuthenticationRequired
const PrivateRoute: React.FC<RouteProps> = ({ component: Component, path, ...rest }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated) return;
    const login = async () => {
      await loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
    };
    login();
  }, [isLoading, isAuthenticated, loginWithRedirect, path]);

  if (!Component) return null;

  const render = (props: RouteComponentProps) =>
    isAuthenticated ? <Component {...props} /> : null;

  return <Route {...rest} path={path} render={render} />;
};

export default PrivateRoute;
