import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import { SIGNATURE_SETTING } from '../../../graphql/queries';
import { SignatureType } from '../../../graphql/types';
import Loading from '../../Loading';
import { useSignatureCreate } from './lib';

import '../../style/buttons.scss';
import '../../style/signature.scss';

interface Inputs {
  signature: string;
}

const Signature: React.FC = () => {
  const { loading, data } = useQuery<SignatureType>(SIGNATURE_SETTING);
  const [saveSignature] = useSignatureCreate();
  const [trimmedDataURL, setTrimmedDataURL] = useState();
  let sigPad: any;

  const clear = () => {
    if (sigPad) {
      sigPad.clear();
    }
    if (trimmedDataURL) {
      setTrimmedDataURL(undefined);
    }
  };

  const trim = () => {
    if (sigPad) {
      setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL('image/png'));
    }
  };

  const save = (values: Inputs) => {
    if (sigPad) {
      saveSignature({
        variables: { data: values },
      });
    }
  };

  const renderPreview = () => {
    return (
      <div>
        {trimmedDataURL ? <img alt="signature" className="sigImage" src={trimmedDataURL} /> : null}
      </div>
    );
  };
  const renderSavedPreview = () => {
    return (
      <div>
        {!trimmedDataURL && data?.signature ? (
          <img alt="signature" className="sigImage" src={data?.signature?.signature} />
        ) : null}
      </div>
    );
  };

  if (loading)
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );

  return (
    <div className="container">
      <div className="sigContainer">
        <SignaturePad
          canvasProps={{ className: 'sigPad' }}
          ref={(ref: any) => {
            sigPad = ref;
          }}
        />
      </div>
      <div className="sigButtonContainer">
        <button type="button" className="add-button clear" onClick={clear}>
          Clear
        </button>
        <button type="button" className="add-button trim" onClick={trim}>
          Preview
        </button>
        <button
          type="button"
          className="add-button"
          onClick={() => save({ signature: sigPad.toDataURL() })}
        >
          Save
        </button>
      </div>
      {renderPreview()}
      {renderSavedPreview()}
    </div>
  );
};

export default Signature;
