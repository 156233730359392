import moment from 'moment';
import { Stos_stos_edges_node } from '../../graphql/generated/Stos';

export const getMonthPercent = (sto: Stos_stos_edges_node, m: string): string => {
  const totalCount = sto.stoCounters.edges.filter((sc: any) => {
    return sc.node.data !== 'Abs' && moment(sc.node.date).format('MM') === m;
  });

  if (totalCount.length < 1) {
    return '0.0';
  }

  const count = totalCount.filter((sc: any) => {
    return sc.node.data === 'Yes';
  });

  return ((count.length / totalCount.length) * 100).toFixed(1);
};

export const getMonths = (stoParams: Stos_stos_edges_node, current: boolean): string[] => {
  const months = [];

  if (stoParams) {
    const { startDate, endDate } = stoParams;
    let temp = moment(startDate);

    while (
      current &&
      ((parseInt(temp.format('M'), 10) <= parseInt(moment(endDate).format('M'), 10) &&
        parseInt(temp.format('YYYY'), 10) === parseInt(moment(endDate).format('YYYY'), 10)) ||
        parseInt(temp.format('YYYY'), 10) < parseInt(moment(endDate).format('YYYY'), 10))
    ) {
      months.push(temp.format('MM'));
      temp = temp.add(1, 'month');
    }
  }

  return months;
};

export const getEdgesFromQuarter = (quarter: number, acumulative = false) => {
  let start;
  let end;
  if (quarter === 1) {
    start = 0;
    end = 1;
  } else if (quarter === 2) {
    start = 1;
    end = 2;
  } else if (quarter === 3) {
    start = 0;
    end = 3;
  } else {
    start = 3;
    end = 4;
  }

  if (acumulative) {
    start = 0;
  }

  return { start, end };
};

export const getGraphData = (plan: any, quarter: number) => {
  const colors = ['#1F85FF', '#E892F7', '#EB6161', '#43C533', '#F88F6C', '#F88F6C'];
  const figure = ['triangle', 'circle', 'star', 'rectRot', 'rect', 'crossRot', 'rectRounded'];
  const { start, end } = getEdgesFromQuarter(quarter, true);
  const datasets: any[] = [];
  const labels: any[] = [];
  let fromDate = '';
  let firstSto = true;

  plan.goals.edges.map((g: any, index: any) => {
    const obj: any = {};

    obj.label = g.node.target;
    obj.borderColor = colors[index];
    obj.backgroundColor = colors[index];
    obj.fill = false;
    obj.pointRadius = 4;
    obj.pointStyle = figure[index];
    obj.data = [];

    g.node.stos.edges.slice(start, end).map((s: any) => {
      const months = getMonths(s.node, true);
      months.map((m: any, i: any) => {
        if (
          i === 0 &&
          !labels.includes(moment(s.node.startDate).endOf('month').format('M/D/YYYY'))
        ) {
          labels.push(moment(s.node.startDate).endOf('month').format('M/D/YYYY'));
          if (firstSto || quarter !== 3) {
            fromDate = moment(s.node.startDate).format('M/D/YYYY');
            firstSto = false;
          }
        } else if (
          !labels.includes(
            moment(s.node.startDate).add(i, 'month').endOf('month').format('M/D/YYYY')
          )
        ) {
          labels.push(moment(s.node.startDate).add(i, 'month').endOf('month').format('M/D/YYYY'));
        }
        const percent = getMonthPercent(s.node, m);
        obj.data.push(percent);

        return null;
      });

      return null;
    });

    datasets.push(obj);

    return null;
  });

  const graphData = {
    labels,
    datasets,
    fromDate,
  };

  return graphData;
};

export const getGraphOptions = () => {
  const graphOptions = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
        title: {
          display: true,
          text: 'A.A Frecuency Graph',
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Percent (%)',
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Months',
        },
      },
    },
  };

  return graphOptions;
};

export const getDays = (sto: Stos_stos_edges_node, month: number) => {
  const days = [];
  let day;
  let dayEnd;

  if (parseInt(moment(sto.startDate).format('M'), 10) === month + 1) {
    day = parseInt(moment(sto.startDate).format('D'), 10);
  } else day = 1;

  if (parseInt(moment(sto.endDate).format('M'), 10) === month + 1) {
    dayEnd = parseInt(moment(sto.endDate).format('D'), 10);
  } else dayEnd = parseInt(moment().month(month).endOf('month').format('D'), 10);

  while (day <= dayEnd) {
    days.push(day);
    day += 1;
  }
  return days;
};
