import { gql } from '@apollo/client';

export const STO_COUNTER_CREATE = gql`
  mutation StoCounterCreate($stoId: ID!, $data: CreateStoCounterInput!) {
    stoCounterCreate(stoId: $stoId, data: $data) {
      date
      data
    }
  }
`;

export const STO_COUNTER_UPDATE = gql`
  mutation StoCounterUpdate($id: ID!, $data: CreateStoCounterInput!) {
    stoCounterUpdate(id: $id, data: $data) {
      id
      date
      data
    }
  }
`;
